import React from 'react';
import Hero from './home/Hero';
import Info from './home/Info';
import Content from './home/Content';
import Highlights from './home/Highlights';
import Features from './home/Features';
const Home = () => {
  return (
    <div>
      <Hero />
      <Info />
      <Content />
      <Highlights/>
      <Features />
    </div>
  )
}

export default Home;
