import React, { useState } from "react";
import '../../people.css';
import { Link } from "react-router-dom";

function Students() {
    const [details, setDetails] = useState([
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
        {
            name: "XYZ",
            yop: "2016",
        },
    ]);
    return (
        <div className="students">
            <div className="people-hero-section">
                <img
                    src="https://plus.unsplash.com/premium_photo-1682787494765-44d02d12f5be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="hero"
                />
                <h1>Students</h1>
            </div>
            <h1
                className="main-head"
                style={{ marginBottom: "1rem",marginTop:"1rem" }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Prospective Students
            </h1>
            <div className="prospective-students">
                <p style={{ marginBottom: "1rem" }}>
                    Get more details on programmes, resources, fees and other
                    information for prospective students here.
                </p>
                <div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/pfs1_2.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>ACADEMIC CURRICULA</h4>
                            <p>Semester dates and deadline</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/askiitm.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>CONFUSED ABOUT JOSAA?</h4>
                            <p>
                                Get doubts cleared by Current Students and
                                Alumini or browse through questions and videos
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Scholarships.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>SCHOLARSHIPS AND ASSISTANCE</h4>
                            <p>Financial assistance and Scholarships</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Undergraduate%20programme.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>UNDERGRADUATE PROGRAMMES</h4>
                            <p>Programmes offered @IITKGP</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Postgraduate%20programmes.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>POSTGRADUATE PROGRAMMES</h4>
                            <p>
                                Residential & Continuiting education Programmes
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Research%20programmes.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>RESEARCH PROGRAMMES</h4>
                            <p>Academic and Sponserd Research programmes</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Academic%20-%20fees%20structure.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>FEE STRUCTURE FOR CURRENT SEMESTER</h4>
                            <p>Schedule & payment details for fees</p>
                        </div>
                    </div>
                </div>
            </div>
            <h1
                className="main-head"
                style={{
                    marginTop: "2rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Current Students
            </h1>
            <div className="current-students">
                <p style={{ marginBottom: "1rem" }}>
                    Find out more about courses, timetables, calendars,
                    curricula, fees and other important information for current
                    students here.
                </p>
                <div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Students%20-Academic%20section%20portal.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>ACADEMIC SECTION PORTAL (INTRANET ONLY)</h4>
                            <p>
                                Information on curriculars, course listings, etc
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Academic%20Timetable.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>TIME TABLE</h4>
                            <p>Common timetable of the institute</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Academic%20Callender.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>CALENDER</h4>
                            <p>Academic calender for different semesters</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/pfs1_3.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>ACADEMIC CURRICULA</h4>
                            <p>Semester dates and deadlines</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Exchange%20program.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>EXCHANGE PROGRAMMES</h4>
                            <p>
                                Collaborations and exchange programs with
                                foreign universities
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Academic%20-%20fees%20structure.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>FEE STRUCTURE FOR CURRENT SEMESTER</h4>
                            <p>Schedule & payment details for fees</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Students%20-%20Pay%20Academic%20Fees.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>PAY ACADEMIC FEES</h4>
                            <p>Pay your academic fees through here</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/Students%20-%20Pay%20Hostel%20fees.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>PAY HOSTEL FEES</h4>
                            <p>Pay your hostel fees through here</p>
                        </div>
                    </div>
                </div>
            </div>
            <h1
                className="main-head"
                style={{
                    marginTop: "2rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Other Sections
            </h1>
            <div className="other-sections">
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691390922_fbea04125f7a88caec09.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691658733_d59c32c5f35e71bc42ad.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659240_13b57714b858ebf130e1.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659301_3373d1c839bda8897ad6.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659357_501db77f521ce9bb4a0d.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659496_f46041651a28a329408c.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659520_c8f9bbcdec1dc3db398e.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659556_462a74218acb44a218d4.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659589_5f12b64f38517b2e423b.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659614_ef0ae8d18a7567ad893d.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691640668_dd826c59efe37cdb0e3b.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659683_eeea57fac68201dd2160.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659727_887a2c2a5381a448633c.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659766_dd4b463697bd811f6f96.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691659838_ebfb09b196208ddfd258.jpg"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1691660058_5e365a81a6a4eaab057e.png"
                        alt="other"
                    />
                </div>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/uploads/menu_icons/1712206752_09bf82a05a6630f47647.png"
                        alt="other"
                    />
                </div>
            </div>
            <h1
                className="main-head"
                style={{
                    marginTop: "2rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Students Details
            </h1>
            <div className="scroll-div">
                <div className="details-div">
                    <table>
                        <thead>
                            <tr>
                                <th className="left-column">Students Name</th>
                                <th className="right-column">
                                    Year of Passing
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((student, index) => (
                                <tr key={index}>
                                    <td className="left-column">
                                        {student.name}
                                    </td>
                                    <td className="right-column">
                                        {student.yop}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Students;
