import React from "react";
import "./programs.css";
import { Link } from "react-router-dom";

const Programs = () => {
    return (
        <div>
            <div class="overlay-pro">
                <img
                    src="https://st4.depositphotos.com/3367263/19970/i/450/depositphotos_199707812-stock-photo-digital-composite-books-graduation-hat.jpg"
                    class="img-fluid-pro"
                    alt="backI"
                />
                <div class="headline-pro">Academic Programs</div>
            </div>

            <div className="programs-pro">
                <h1>Academic Programs</h1>
                <div className="programs-list-pro">
                    <div class="program-pro">
                        <Link to="#programs">
                            <h2> Programs</h2>
                            <p>Details about the new academic program</p>
                        </Link>
                    </div>
                    <div class="program-pro">
                        <Link to="#ug-curricula">
                            <h2> UG Curricula</h2>
                            <p>Details about the Undergraduate Curricula</p>
                        </Link>
                    </div>
                    <div class="program-pro">
                        <Link to="#pg-curricula">
                            <h2> PG Curricula</h2>
                            <p>Details about the Postgraduate Curricula</p>
                        </Link>
                    </div>
                    <div class="program-pro">
                        <Link to="#research">
                            <h2>Certificate of Excellence in Research</h2>
                            <p>Information about the Certificate of Excellence in Research</p>
                        </Link>
                    </div>
                </div>
            </div>

            <div id="programs" className="programs1-pro">
                <h1 >Programs</h1>
                <div className="content-pro">
                    <p>
                        At IIT KGP, academics is designed to give wings to your
                        aspirations - more than teach, we help you learn and
                        innovate.
                    </p>
                    <p>
                        A wide variety of disciplines (19 Departments, 13
                        Schools and 9 Centres of Excellence) are offered which
                        are also amalgamated, thus making available a plethora
                        of multidisciplinary courses.
                    </p>
                    <p>
                        Students are encouraged to explore the options which
                        suit them the best, irrespective of the discipline to
                        which they have been admitted. This improves their
                        industry worthiness and knowledge base and helps them
                        emerge as well-rounded graduates, equipped for any
                        career either in academics or the corporate world.
                    </p>
                    <p>
                        Opportunities for research, fellowships, internships and
                        networking are boundless.
                    </p>
                    <p>
                        Our admission criteria are among the most rigorous in
                        the country. But on admission, students are supported
                        with:
                    </p>
                    <ul>
                        <li>Best-in-class Technical Facilities</li>
                        <li>Web-enabled Classrooms</li>
                        <li>Virtual Learning Environment</li>
                        <li>Use of Learning Management Tools, viz. Moodle</li>
                        <li>Discussion Forum</li>
                    </ul>
                    <h2>Academic programs</h2>
                    <ul>
                        <li>B.Tech. (15 programs)</li>
                        <li>B.Arch, Dual Degree (35 programs)</li>
                        <li>
                            MBA, MHRM, LLB, MMST, MCP, M.Sc/ M.Tech/ MS (67
                            programs)
                        </li>
                        <li>Ph.D programs</li>
                    </ul>
                    <h2>Quick links</h2>
                    <ul>
                        <li>Academic Curriculum</li>
                        <li>Interdisciplinary Programs</li>
                        <li>Minor Courses</li>
                        <li>Micro-specializations</li>
                        <li>List of Departments, Schools and Centres</li>
                    </ul>
                </div>
            </div>
            <div id="ug-curricula" className="curricula-ug-pro">
                <h1>Curricula UG</h1>
                <div className="curricula-list-pro">
                    <div className="curricula-item-pro">
                        <div className="icon-pro">&#9733;</div>
                        <h2>curricula (Admission till year 2019)</h2>
                        <p>
                            Details about curricula for admissions till the year
                            2019.
                        </p>
                    </div>
                    <div className="curricula-item-pro">
                        <div className="icon-pro">&#9733;</div>
                        <h2>curricula (Admission year 2020-2023)</h2>
                        <p>
                            Details about curricula for admissions from 2020 to
                            2023.
                        </p>
                    </div>
                    <div className="curricula-item-pro">
                        <div className="icon-pro">&#9733;</div>
                        <h2>curricula (Admission year 2024 onwards)</h2>
                        <p>
                            Details about curricula for admissions from the year
                            2024 onwards.
                        </p>
                    </div>
                </div>
            </div>
            <div id="pg-curricula" className="curricula-pg-pro">
                <h1>Curricula PG</h1>
                <div className="curricula-list-pg-pro">
                    <div className="curricula-item-pg-pro">
                        <div className="icon-pg-pro">&#9733;</div>
                        <h2>Curricula PG</h2>
                        <p>
                            The curricula for PG programs are designed to
                            provide advanced knowledge and specialized skills in
                            various fields. The programs aim to deepen
                            understanding through research-oriented learning and
                            practical applications. Each program is structured
                            to equip students with the expertise needed to excel
                            in their chosen careers, preparing them for
                            leadership roles and innovative contributions.
                        </p>
                    </div>
                </div>
            </div>
            <div id="research" class="certificate-section-pro-certi">
                <div class="certificate-image-pro-certi">
                    <img
                        src="https://www.iitkgp.ac.in/assets/images/about_01.jpg"
                        alt="IIT Kharagpur"
                        class="certificate-img-pro-certi"
                    />
                </div>
                <div class="certificate-content-pro-certi">
                    <h2 class="certificate-title-pro-certi">
                        Open Research Associateship @IITKgp
                    </h2>
                    <p>
                        IIT Kharagpur brings a unique opportunity for
                        professionals with doctoral degrees, MD/MS (in clinical
                        specialities) degrees to be recognized for cutting edge,
                        collaborative research.
                    </p>
                    <p>
                        We will help you realize your dream to pursue your
                        research ideas.
                    </p>
                    <p>
                        The Certificate of Excellence in Research (CER) is open
                        to bright professionals in India and abroad with
                        doctoral degrees, working in industry or research
                        institutions, with a desire to pursue impactful research
                        in academia and be recognized globally.
                    </p>
                    <p>
                        We will help you develop a research proposal in
                        collaboration with our faculty. While you continue with
                        your present professional engagement, you can opt for
                        this program at IIT Kharagpur in consultation with your
                        organization.
                    </p>
                    <a href="#" class="apply-button-pro-certi">
                        Apply Online
                    </a>
                </div>
            </div>

            <div class="additional-content-pro-certi">
                <h3>CER for Clinical Specialists</h3>
                <p>
                    IIT Kharagpur is determined to contribute in the domain of
                    diagnostics and therapy through collaborative work towards
                    cutting-edge healthcare solutions. School of Medical Science
                    and Technology, IIT Kharagpur jointly with Medical
                    Institutions envisions to launch the Certificate of
                    Excellence in Research (CER) program particularly for
                    training and accomplishments of clinical specialists in the
                    domain of clinical oncology, radiomics, affordable health,
                    diagnostics and therapy to initiate and recognize
                    state-of-the-art collaborative research with bright
                    professionals working in relevant domains having Doctor of
                    Medicine (MD)/ Master of Surgery (MS) degree in clinical
                    subjects and engaged in industry, academia and national and
                    international laboratories.
                </p>
                <p>
                    "School of Medical Science and Technology with its proposed
                    Multi-Specialty Research Hospital, has to transform into a
                    virtual medical research university drawing the best
                    expertise from various institutions from the country and
                    abroad." - President A.P.J. Abdul Kalam
                </p>
                <h3>Certificate of Excellence Award</h3>
                <ul>
                    <li>
                        Publication of two papers in SCI - indexed Journals with
                        affiliation of IIT Kharagpur and co-authorship with IIT
                        Kharagpur faculty.
                    </li>
                    <li>
                        Grant of a patent jointly with IIT Kharagpur will be
                        considered equivalent to a publication.
                    </li>
                    <li>
                        The candidates are supposed to work from their workplace
                        with a close interaction with the Host Faculty Member
                        including visits for discussion / experimentation,
                        whenever needed.
                    </li>
                    <li>
                        The research work cannot be included in any other
                        academic program.
                    </li>
                </ul>
                <h3>What We Offer</h3>
                <ul>
                    <li>
                        Collaboration with a Host Faculty from IIT Kharagpur.
                    </li>
                    <li>
                        The Certificate of Excellence in Research will be
                        awarded during the Convocation of the Institute.
                    </li>
                    <li>
                        The candidates enrolled in this program can join the
                        Global Initiative for Academic Networks, Continuing
                        Education Programme, Knowledge Dissemination Programme
                        and postgraduate level micro-credit courses.
                    </li>
                    <li>
                        External candidates will be able to access the host
                        faculty laboratory, centralized research and other
                        experimental and computing facilities, and libraries of
                        the Institute.
                    </li>
                </ul>
            </div>
        </div>
    );
};

const Program = ({ title, description }) => {
    return (
        <div className="program-pro">
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    );
};

export default Programs;
