import React from "react";
import "./News.css";
import { Link } from "react-router-dom";
const News = () => {
    return (
        <>
            <div className="first">
                <img
                    src="https://img.freepik.com/premium-photo/businessman-reads-newspaper-busy-city-street_14117-760978.jpg?w=1060"
                    alt=""
                />
                <h1 className="text-overlay">NEWS</h1>
            </div>

            <div className="middle-news">
                <h1>
                    {" "}
                    <span>|</span> NEWS
                </h1>
            </div>

            <section class="articles">
                <article>
                    <div class="article-wrapper ">
                        <figure>
                            <img
                                src="https://img.freepik.com/free-photo/young-beautiful-woman-doing-yoga-nature_1139-909.jpg?ga=GA1.1.1496753159.1711695095&semt=ais_hybrid"
                                alt="#"
                            />
                        </figure>
                        <div class="article-body">
                            <h2> International yoga 2024</h2>
                            <p>
                                Join us for International Yoga 2024 at IIT
                                Kharagpur, where we embrace holistic wellness
                                and mindfulness. This event will feature expert
                                sessions, workshops, and practices designed to
                                enhance physical and mental well-being.
                                Experience a diverse range of yoga techniques
                                and gain insights into integrating wellness into
                                daily life.
                            </p>
                            <Link to="/news/subnews">
                                <button className="click">Read More</button>
                            </Link>
                        </div>
                    </div>
                </article>
                <article>
                    <div class="article-wrapper">
                        <figure>
                            <img
                                src="https://img.freepik.com/premium-photo/attentive-audience-listening-presentation-modern-office_999549-56805.jpg?ga=GA1.1.1496753159.1711695095&semt=ais_hybrid"
                                alt=""
                            />
                        </figure>
                        <div class="article-body">
                            <h2>workshop on Quantum Technologies</h2>
                            <p>
                                Explore the cutting-edge field of Quantum
                                Technologies at our specialized workshop. This
                                event will delve into the latest advancements,
                                practical applications, and theoretical insights
                                of quantum computing and quantum mechanics. Join
                                leading experts and researchers to enhance your
                                understanding and contribute to groundbreaking
                                developments in this exciting area.
                            </p>
                            <Link to="/news/subnews">
                                <button className="click">Read More</button>
                            </Link>
                        </div>
                    </div>
                </article>
                <article>
                    <div class="article-wrapper">
                        <figure>
                            <img
                                src="https://img.freepik.com/premium-photo/people-standing-hill-with-city-background_1234220-38643.jpg?ga=GA1.1.1496753159.1711695095&semt=ais_hybrid"
                                alt=""
                            />
                        </figure>
                        <div class="article-body">
                            <h2>
                                {" "}
                                Celebrates World Environment Day 2024 05 Jun
                                2024
                            </h2>
                            <p>
                                Join us at IIT Kharagpur as we observe World
                                Environment Day with a series of engaging
                                activities and discussions aimed at raising
                                awareness about environmental conservation.
                                Participate in workshops, lectures, and
                                community initiatives to learn about sustainable
                                practices and contribute to environmental
                                protection efforts.
                            </p>
                            <Link to="/news/subnews">
                                <button className="click">Read More</button>
                            </Link>
                        </div>
                    </div>
                </article>

                <article>
                    <div class="article-wrapper ">
                        <figure>
                            <img
                                src="https://img.freepik.com/free-photo/people-celebrating-indian-republic-day_23-2151142105.jpg?t=st=1723468046~exp=1723471646~hmac=4f900ab1275e0f9aa639380d2b56a3bbaac23c726eb0ef1c311264c3c6ff0f5e&w=1060"
                                alt="#"
                            />
                        </figure>
                        <div class="article-body">
                            <h2>
                                IIT Kharagpur Celebrates the 77th Independence
                                Day
                            </h2>

                            <p>
                                Join us in commemorating the 77th Independence
                                Day at IIT Kharagpur. The event will feature a
                                series of cultural performances, speeches, and
                                activities that honor our nation’s history and
                                achievements. Participate in this meaningful
                                celebration as we reflect on our progress and
                                renew our commitment to national development.
                            </p>
                            <Link to="/news/subnews">
                                <button className="click">Read More</button>
                            </Link>
                        </div>
                    </div>
                </article>

                <article>
                    <div class="article-wrapper ">
                        <figure>
                            <img
                                src="https://as2.ftcdn.net/v2/jpg/03/07/74/73/1000_F_307747326_WK0egPY41E0ZpouWZunlM3JvGXmWzgJM.jpg"
                                alt="#"
                            />
                        </figure>
                        <div class="article-body">
                            <h2>
                                Bipartisan US Congressional Delegation Visits
                                IIT Kharagpur
                            </h2>
                            <p>
                                We are honored to host a bipartisan US
                                Congressional delegation at IIT Kharagpur. The
                                visit will include discussions on collaborative
                                research opportunities, academic partnerships,
                                and innovations in technology and education.
                                This event aims to strengthen ties and explore
                                avenues for mutual growth and development
                                between our institutions.
                            </p>
                            <Link to="/news/subnews">
                                <button className="click">Read More</button>
                            </Link>
                        </div>
                    </div>
                </article>

                <article>
                    <div class="article-wrapper ">
                        <figure>
                            <img
                                src="https://img.freepik.com/free-photo/improvement-potential-excellence-diagram-graphic-concept_53876-127589.jpg?t=st=1723468554~exp=1723472154~hmac=1fc873fbc0bead7d631a936e29c0e9b7cb873a43d4ce152c9f903c9ea0297fd3&w=900"
                                alt="#"
                            />
                        </figure>
                        <div class="article-body">
                            <h2>
                                IIT Kharagpur Launches New Centre of Excellence
                                on [Topic]
                            </h2>
                            <p>
                                We are excited to announce the launch of a new
                                Centre of Excellence at IIT Kharagpur dedicated
                                to [specific area or topic]. This center aims to
                                drive innovation, foster cutting-edge research,
                                and facilitate interdisciplinary collaboration.
                                Join us as we embark on this new venture to
                                advance knowledge and create impactful solutions
                                in [field/topic].
                            </p>
                            <Link to="/news/subnews">
                                <button className="click">Read More</button>
                            </Link>
                        </div>
                    </div>
                </article>
            </section>
        </>
    );
};

export default News;
