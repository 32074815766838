import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./Alumini.css";
const Alumini = () => {
    return (
        <div>
            <div class="container-alumini">
                {/* <div class="image-container-alumini"> </div> */}
                <div class="text-content-alumini">
                    <h1>Welcome To Alumini Of IIT-KGP</h1>
                </div>
            </div>
            <div class="alumni-section1">
                <h3 class="alumni-heading">Notable Alumni of IIT Kharagpur</h3>
                <p class="alumni-content">
                    IIT Kharagpur's alumni network spans across the globe, with
                    members excelling in diverse fields such as technology,
                    entrepreneurship, academia, and public service. These
                    distinguished individuals have made significant
                    contributions to society, shaping industries and leading
                    innovations. The institute takes pride in nurturing minds
                    that not only excel in their careers but also inspire others
                    to achieve greatness. The achievements of these alumni
                    continue to highlight the legacy of IIT Kharagpur as a
                    beacon of excellence.
                </p>
            </div>

            <section className="heros-sec" style={{ marginLeft: "-80px" }}>
                <Container className="homecontainer">
                    <Row>
                        <Col lg="6" className="hero-content-col">
                            <div className="hero__content">
                                <div className="mb-4 hero__title">
                                    <h3>Alumni Network Overview</h3>
                                </div>
                                <p style={{ color: "black" }}>
                                    Our alumni network is rich and diverse,
                                    reflecting the excellence and achievements
                                    of our graduates. Many alumni have
                                    experienced unique career paths and
                                    significant contributions to their fields,
                                    often overcoming challenges and adapting to
                                    evolving industries. To ensure the integrity
                                    and value of our network, we continuously
                                    curate and update information to maintain
                                    relevance and accuracy. By leveraging our
                                    extensive database and global connections,
                                    we strive to support our alumni in their
                                    professional endeavors and keep them engaged
                                    with the IIT Kharagpur community. Our
                                    commitment is to provide a robust platform
                                    that connects alumni and fosters ongoing
                                    collaboration.
                                </p>
                            </div>
                        </Col>

                        <Col lg="6">
                            <img
                                src="https://images.unsplash.com/photo-1721208518918-0ea95b7979fd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                alt=""
                                className="w-100 hero__img"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <div class="alumini-section">
                <h1>Distinguished Alumni</h1>
                <p class="description-alumini">
                    Distinguished Alumni of IIT Kharagpur are celebrated for
                    their outstanding contributions to various fields, including
                    technology, entrepreneurship, and public service. They
                    exemplify the institute's legacy of excellence and
                    innovation on both national and global stages.
                </p>
                <div class="alumini-container">
                    <div class="alumini-member">
                        <img
                            src="https://static.vecteezy.com/system/resources/previews/041/640/706/non_2x/ai-generated-happy-teacher-cut-out-smiling-young-school-teacher-woman-on-transparent-background-png.png"
                            alt="Jamie Sergeant"
                        />
                        <h3>Subir Kumar Banerjee </h3>
                        <p>Emeritus and Founding Director</p>
                    </div>
                    <div class="alumini-member">
                        <img
                            src="https://static.vecteezy.com/system/resources/previews/041/640/706/non_2x/ai-generated-happy-teacher-cut-out-smiling-young-school-teacher-woman-on-transparent-background-png.png"
                            alt="Emma Glazier"
                        />
                        <h3>Hirak Kumar Sen</h3>
                        <p>Founder and CEO</p>
                    </div>
                    <div class="alumini-member">
                        <img
                            src="https://static.vecteezy.com/system/resources/previews/041/640/706/non_2x/ai-generated-happy-teacher-cut-out-smiling-young-school-teacher-woman-on-transparent-background-png.png"
                            alt="Tom Berne"
                        />
                        <h3>Sarbari Gupta</h3>
                        <p>CEO, Electrosoft</p>
                    </div>
                    <div class="alumini-member">
                        <img
                            src="https://static.vecteezy.com/system/resources/previews/041/640/706/non_2x/ai-generated-happy-teacher-cut-out-smiling-young-school-teacher-woman-on-transparent-background-png.png"
                            alt="Adam Smithson"
                        />
                        <h3>Sundar Pichai</h3>
                        <p>Executive Officer of Alphabet</p>
                    </div>
                    <div class="alumini-member">
                        <img
                            src="https://static.vecteezy.com/system/resources/previews/041/640/706/non_2x/ai-generated-happy-teacher-cut-out-smiling-young-school-teacher-woman-on-transparent-background-png.png"
                            alt="Adam Smithson"
                        />
                        <h3>Mohan Rao</h3>
                        <p>Chief Science Officer</p>
                    </div>
                    <div class="alumini-member">
                        <img
                            src="https://static.vecteezy.com/system/resources/previews/041/640/706/non_2x/ai-generated-happy-teacher-cut-out-smiling-young-school-teacher-woman-on-transparent-background-png.png"
                            alt="Adam Smithson"
                        />
                        <h3>Arjun Nohwar</h3>
                        <p>Former Head, Uber</p>
                    </div>
                </div>
            </div>
            <div className="success-alumini">
                <h1>Alumni Success</h1>
            </div>
            <div className="card-container-alumini">
                <div className="img-alumini">
                    <iframe
                        className="ii-alumini"
                        src="https://www.youtube.com/embed/8gIR7571Vag?si=093uxZ2AZ_wW6fp7"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="img-alumini">
                    <img
                        className="ii-alumini"
                        src="https://images.unsplash.com/photo-1523240795612-9a054b0db644?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="#"
                    />
                </div>
                <div className="img-alumini">
                    <img
                        className="ii-alumini"
                        src="https://images.unsplash.com/photo-1517486808906-6ca8b3f04846?q=80&w=1949&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="#"
                    />
                </div>
            </div>
            <div className="card-container-alumini">
                <div className="img-alumini">
                    <img
                        className="ii-alumini"
                        src="https://images.unsplash.com/photo-1525921429624-479b6a26d84d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="#"
                    />
                </div>
                <div className="img-alumini">
                    <iframe
                        className="ii-alumini"
                        src="https://www.youtube.com/embed/8gIR7571Vag?si=093uxZ2AZ_wW6fp7"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="img-alumini">
                    <img
                        className="ii-alumini"
                        src="https://images.unsplash.com/photo-1620458738323-4148c6093433?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="#"
                    />
                </div>
            </div>
        </div>
    );
};

export default Alumini;
