import React from 'react';
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer bg-[#281981] text-white" id='test' style={{marginTop:"0rem"}}>
      <div className="container mx-auto px-8 py-8 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
          <div className="footer-links-div space-y-4 xl:col-span-1">
            <a href="/" className="flex items-center space-x-2 text-2xl font-medium">
              <img
                src="https://www.svgrepo.com/show/452102/slack.svg"
                alt="AI Logo"
                width="64"
                height="64"
                className="w-16"
              />
              <span className="text-white">Department Of Physics</span>
            </a>
            <p className="max-w-md pr-16 text-gray-200 text-md">
              The motto of IIT Kharagpur is "योगः कर्मसु कौशलम्". This literally translates to
              "Excellence in action is Yoga".
            </p>
            <div className="footer-links flex space-x-4">
              <a href="#" className="text-gray-200 hover:text-gray-100">
                <span className="sr-only">Linkedin</span>
                <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a href="#" className="text-gray-200 hover:text-gray-100">
                <span className="sr-only">Twitter</span>
                <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
                  <path
                    d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                  />
                </svg>
              </a>
              <a href="#" className="text-gray-200 hover:text-gray-100">
                <span className="sr-only">Linkedin</span>
                <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a href="#" className="text-gray-200 hover:text-gray-100">
                <span className="sr-only">Linkedin</span>
                <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a href="#" className="text-gray-200 hover:text-gray-100">
                <span className="sr-only">Linkedin</span>
                <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>

          <div className="footer-content-div grid grid-cols-1 md:grid-cols-2 gap-8 xl:col-span-3">
            <div>
              <h3 className="text-md  font-semibold leading-6">Our Solutions</h3>
              <ul className="mt-6 space-y-4">
                <li>
                  <a href="/aiplatform" className="hover:text-gray-100">
                    AI Platform
                  </a>
                </li>
                <li>
                  <a href="/aialgorithms" className="hover:text-gray-100">
                    AI Algorithms
                  </a>
                </li>
                <li>
                  <a href="/industryapplications" className="hover:text-gray-100">
                    Industry Applications
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-md font-semibold leading-6">Use Cases</h3>
              <ul className="mt-6 space-y-4">
                <li>
                  <a href="/predictiveanalysis" className="hover:text-gray-100">
                    Predictive Analysis
                  </a>
                </li>
                <li>
                  <a href="/customerexperience" className="hover:text-gray-100">
                    Customer Experience
                  </a>
                </li>
                <li>
                  <a href="/automation" className="hover:text-gray-100">
                    Automation
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-md font-semibold leading-6">Resources</h3>
              <ul className="mt-6 space-y-4">
                <li>
                  <a href="/pricing" className="hover:text-gray-100">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="/blog" className="hover:text-gray-100">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/casestudies" className="hover:text-gray-100">
                    Case Studies
                  </a>
                </li>
                <li>
                  <a href="/terms" className="hover:text-gray-100">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href="/privacy" className="hover:text-gray-100">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-md font-semibold leading-6">Company</h3>
              <ul className="mt-6 space-y-4">
                <li>
                  <a href="/aboutus" className="hover:text-gray-100">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/careers" className="hover:text-gray-100">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="/contactus" className="hover:text-gray-100">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-16 border-t border-gray-400/30 pt-8 text-center">
          <p className="text-gray-300">
            Copyright © 2024. Crafted with <span className="text-gray-50">♥</span> by AI enthusiasts at
            <a rel="noopener" href="/" className="text-gray-50 hover:underline">
              AIOps
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
