import React from 'react';
import './hero.css';

const Hero = () => {
  return (
    <section className="hero-section relative h-screen flex flex-col items-center justify-center text-center text-white">
      <div className="hero-video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/vCXqlgZ-N2Q?si=noM7U0FbHA493c1M"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title="Hero Video"
        ></iframe>
      </div>
    </section>
  );
};

export default Hero;