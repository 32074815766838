import React, { useState } from "react";
import "./nav.css";
import { NavLink } from "react-router-dom";

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isPeopleOpen, setIsPeopleOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const togglePeopleMenu = () => {
        setIsPeopleOpen(!isPeopleOpen);
    };
    const closePeopleMenu = () => {
        setIsPeopleOpen(false);
    };
    
    return (
        <>
            <div className="nav1">
                <nav className="bg-[#281981] p-6 border-gray-200 dark:bg-[#281981]">
                    <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                        {/* <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse"> */}
                        {/* <img src="https://flowbite.com/docs/images/logo.svg" className="h-8" alt="Flowbite Logo" /> */}
                        <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                            Department OF Physics
                        </span>
                        {/* </a> */}
                        <div className="flex items-center space-x-6 rtl:space-x-reverse">
                            <NavLink
                                to="/news"
                                className={({ isActive }) =>
                                    `text-lg text-white dark:text-white hover:underline ${
                                        isActive ? "underline" : ""
                                    }`
                                }
                            >
                                News
                            </NavLink>
                            {/* <NavLink to="/news" className="text-lg text-white dark:text-white hover:underline">News</NavLink> */}
                            <NavLink
                                to="/events"
                                className={({ isActive }) =>
                                    `text-lg text-white dark:text-white hover:underline ${
                                        isActive ? "underline" : ""
                                    }`
                                }
                            >
                                Events
                            </NavLink>
                            <NavLink
                                to="/alumini"
                                className={({ isActive }) =>
                                    `text-lg text-white dark:text-white hover:underline ${
                                        isActive ? "underline" : ""
                                    }`
                                }
                            >
                                Alumini
                            </NavLink>
                            <NavLink
                                to="/giving"
                                className={({ isActive }) =>
                                    `text-lg text-white dark:text-white hover:underline ${
                                        isActive ? "underline" : ""
                                    }`
                                }
                            >
                                Giving
                            </NavLink>
                        </div>
                        <button
                            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            aria-controls="navbar-default"
                            aria-expanded={isOpen}
                            onClick={toggleMenu}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </nav>

                <nav
                    className={`bg-[#D9D9D9] dark:bg-[#D9D9D9] ${
                        isOpen ? "block" : "hidden"
                    } md:block`}
                >
                    <div className="max-w-screen-xl px-4 py-3 mx-auto">
                        <div className=" flex flex-col md:flex-row md:items-center justify-between">
                            <ul className="flex flex-col md:flex-row font-medium mt-0 space-y-4 md:space-y-0 md:space-x-8 rtl:space-x-reverse text-lg w-full">
                                <li>
                                    <NavLink
                                        to="/"
                                        className={({ isActive }) =>
                                            `text-[#281981]  dark:text-[#281981] hover:underline ${
                                                isActive ? "underline" : ""
                                            }`
                                        }
                                        aria-current="page"
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/aboutus"
                                        className={({ isActive }) =>
                                            `text-[#281981] dark:text-[#281981] hover:underline ${
                                                isActive ? "underline" : ""
                                            }`
                                        }
                                    >
                                        About us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/academic"
                                        className={({ isActive }) =>
                                            `text-[#281981] dark:text-[#281981] hover:underline ${
                                                isActive ? "underline" : ""
                                            }`
                                        }
                                    >
                                        Academics
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/phdpage"
                                        className={({ isActive }) =>
                                            `text-[#281981] dark:text-[#281981] hover:underline ${
                                                isActive ? "underline" : ""
                                            }`
                                        }
                                    >
                                        PHD-Programs
                                    </NavLink>
                                </li>

                                <li className="relative group block md:block">
                                    <NavLink
                                        to="/research"
                                        className={({ isActive }) =>
                                            `text-[#281981] dark:text-[#281981] hover:underline ${
                                                isActive ? "underline" : ""
                                            }`
                                        }
                                    >
                                        Research
                                    </NavLink>
                                </li>
                                <li className="relative group block md:block">
                                    <NavLink
                                        to="#"
                                        onClick={togglePeopleMenu}
                                        className={({ isActive }) =>
                                            `text-[#281981] dark:text-[#281981] hover:underline ${
                                                window.location.pathname.startsWith(
                                                    "/people"
                                                )
                                                    ? "underline"
                                                    : ""
                                            }`
                                        }
                                    >
                                        People
                                    </NavLink>
                                    {isPeopleOpen && (
                                        <ul
                                            className="absolute hidden group-hover:block bg-[#D9D9D9] dark:bg-[#D9D9D9] -mt-1 w-40"
                                            onMouseLeave={closePeopleMenu}
                                        >
                                            <li>
                                                <NavLink
                                                    to="/people/faculty"
                                                    className="block px-4 py-2 text-[#281981] dark:text-[#281981] hover:bg-white dark:hover:bg-white"
                                                >
                                                    Faculty
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/people/researchers"
                                                    className="block px-4 py-2 text-[#281981] dark:text-[#281981] hover:bg-white dark:hover:bg-white"
                                                >
                                                    Researchers
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/people/students"
                                                    className="block px-4 py-2 text-[#281981] dark:text-[#281981] hover:bg-white dark:hover:bg-white"
                                                >
                                                    Students
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/people/technical"
                                                    className="block px-4 py-2 text-[#281981] dark:text-[#281981] hover:bg-white dark:hover:bg-white"
                                                >
                                                    Technical Staff
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/people/administration"
                                                    className="block px-4 py-2 text-[#281981] dark:text-[#281981] hover:bg-white dark:hover:bg-white"
                                                >
                                                    Administrative Staff
                                                </NavLink>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <li>
                                    <NavLink
                                        to="/contact"
                                        className={({ isActive }) =>
                                            `text-[#281981] dark:text-[#281981] hover:underline ${
                                                isActive ? "underline" : ""
                                            }`
                                        }
                                    >
                                        Contact
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Nav;
