import React from "react";
import "./SubNews.css";
import { Link } from "react-router-dom";

const SubNews = () => {
    return (
        <div className="subnews">
            <div
                id="carouselExampleIndicators"
                class="carousel slide carousel-news"
                data-bs-ride="carousel"
                data-bs-interval="5000"
            >
                <ol class="carousel-indicators">
                    <li
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        class="active"
                    ></li>
                    <li
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                    ></li>
                    <li
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                    ></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="d-block"
                            src="https://img.freepik.com/free-vector/newspaper-page-realistic-vintage_1284-25429.jpg?t=st=1723012011~exp=1723015611~hmac=e5779caa16bc51ee6b5063a46d57de9c78d3d3b8a9e155b54c5a82cc509a32f3&w=740"
                            alt="First slide"
                        />
                    </div>
                    <div class="carousel-item">
                        <img
                            class="d-block w-100"
                            src="https://img.freepik.com/free-vector/newspaper-page-realistic-vintage_1284-25429.jpg?t=st=1723012011~exp=1723015611~hmac=e5779caa16bc51ee6b5063a46d57de9c78d3d3b8a9e155b54c5a82cc509a32f3&w=740"
                            alt="Second slide"
                        />
                    </div>
                    <div class="carousel-item">
                        <img
                            class="d-block w-100"
                            src="https://img.freepik.com/free-vector/newspaper-page-realistic-vintage_1284-25429.jpg?t=st=1723012011~exp=1723015611~hmac=e5779caa16bc51ee6b5063a46d57de9c78d3d3b8a9e155b54c5a82cc509a32f3&w=740"
                            alt="Third slide"
                        />
                    </div>
                </div>
                <a
                    class="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-bs-slide="prev"
                >
                    <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a
                    class="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-bs-slide="next"
                >
                    <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <h1
                className="goback-link"
                style={{
                    marginTop: "2rem",
                    marginLeft: "2rem",
                }}
            >
                <Link
                    to="/news"
                >
                    <i class="bi bi-arrow-left" style={{marginRight:".5rem"}}></i>
                    Go back
                </Link>
            </h1>
            <h1
                style={{
                    marginTop: "2rem",
                    marginLeft: "2rem",
                }}
            >
                | IIT Kharagpur Celebrates International Day of Yoga 2024
            </h1>
            <div className="lorem">
                <p>
                    <p>
                        IIT Kharagpur is thrilled to celebrate International Day
                        of Yoga 2024 with a series of enriching activities and
                        sessions designed to promote wellness and mindfulness.
                        This special event will include:
                    </p>
                    <ul>
                        <li>
                            <strong>Expert-Led Yoga Sessions:</strong>{" "}
                            Participate in guided yoga practices led by renowned
                            instructors, catering to all levels of experience,
                            from beginners to advanced practitioners.
                        </li>
                        <li>
                            <strong>Holistic Health Workshops:</strong> Engage
                            in workshops focused on the broader aspects of
                            health, including nutrition, mental well-being, and
                            stress management.
                        </li>
                        <li>
                            <strong>Interactive Discussions:</strong> Attend
                            talks and discussions about the benefits of
                            integrating yoga into daily life, and explore how it
                            can enhance overall quality of life.
                        </li>
                        <li>
                            <strong>Community Activities:</strong> Join fellow
                            students, faculty, and staff in community-building
                            exercises and group yoga sessions to foster a sense
                            of unity and collective well-being.
                        </li>
                    </ul>
                    <p>
                        This day is an opportunity to deepen your understanding
                        of yoga, connect with the IIT Kharagpur community, and
                        commit to a lifestyle that embraces health and harmony.
                        Don’t miss out on this chance to improve your well-being
                        and experience the transformative power of yoga.
                    </p>
                </p>
            </div>

            <div className="RecentNews">
                <h1>Recent News</h1>
                <div>
                    <hr />
                    <Link to="">
                        <p>IIT kgp celebrates Van Mahotsav</p>
                    </Link>
                    <hr />
                    <Link to="">
                        <p>
                            IIT kgp Celebrates International Day of Yoga 2024{" "}
                        </p>
                    </Link>

                    <hr />
                    <Link to="">
                        <p>
                            IIT kgp and University of Chicago organise a two-day
                            joint workshop on Quantum Technologies
                        </p>{" "}
                    </Link>

                    <hr />
                    <Link to="">
                        <p>IIT kgp Celebrates World Environment Day 2024</p>
                    </Link>
                    <hr />
                    <Link to="">
                        <p>
                            QS WUR 2025: IIT kgp rises 31 places to rank 118th
                            globally
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SubNews;
