import React from "react";
import "../../people.css";
import { Link } from "react-router-dom";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";

function Faculty() {
    return (
        <div className="faculty">
            {/* <header>
                <div>
                    <h1>Physics Department</h1>
                    <input type="search" placeholder="Search faculty here" />
                </div>
                <button className="home-link">
                    <Link to="/">Home</Link>
                </button>
            </header> */}
            <div className="people-hero-section">
                <img
                    src="https://images.unsplash.com/photo-1524178232363-1fb2b075b655?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="hero"
                />
                <h1>Faculty</h1>
            </div>
            <h1
                className="main-head"
                style={{
                    // marginLeft: "2rem",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Prospective Faculty
            </h1>
            <div className="prospective-faculty">
                <p style={{ marginBottom: "1rem" }}>
                    From academic curricula to available positions within the
                    Institute, find all the essential information for
                    prospective faculty and staff here.
                </p>
                <div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/pfs1_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>ACADEMIC CURRICULA</h4>
                            <p>Semester dates and deadline</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/pfs2_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>PORTAL FOR PROSPECTIVE FACULTY</h4>
                            <p>
                                Information on opportunities for prospective
                                faculty
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/pfs3_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>INFORMATION FOR VISITING FACULTY</h4>
                            <p>Details of Visa requirements</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/pfs4_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>FACULTY POSITIONS</h4>
                            <p>Details of Faculty openings @ IIT KGP</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/pfs5_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>NON_TEACHING POSITIONS</h4>
                            <p>
                                Advertisements & updates for non-teaching
                                positions at IIT KGP
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/pfs6_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>HOUSING FOR FACULTY</h4>
                            <p>
                                Information of the types of accomodation
                                available for faculty
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <h1
                className="main-head"
                style={{
                    // marginLeft: "2rem",
                    marginTop: "2rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Current Faculty
            </h1>
            <div className="current-faculty">
                <p style={{ marginBottom: "1rem" }}>
                    Discover everything you need for your life at IIT Kharagpur.
                    Access your faculty and staff portal, view your calendar,
                    see your workflow and more here.
                </p>
                <div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/cfs1_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>CALENDER</h4>
                            <p>Semester dates and deadline</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/cfs2_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>CURRICULA</h4>
                            <p>Semester dates and deadline</p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/cfs3_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>ACADEMICS</h4>
                            <p>
                                Information on circulars, course listing, etc.
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/cfs4_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>COURSES</h4>
                            <p>
                                All-in-one academic platform for managing
                                assignments and additional course material
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/cfs5_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>WORKFLOW PORTAL (INTRANET ONLY)</h4>
                            <p>
                                Official registration, addition, dropping of
                                courses, etc.
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/cfs6_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>FACULTY PORTAL</h4>
                        </div>
                    </div>
                    <div>
                        <img
                            src="https://www.iitm.ac.in/sites/default/files/card_images/cfs7_0.jpg"
                            alt="academic curricula"
                        />
                        <div>
                            <h4>TEACHING SUPPORT/FACULTY DEVELOPMENT</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h1
                className="main-head"
                style={{
                    // marginLeft: "2rem",
                    marginTop: "2rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Faculty Details
            </h1>
            <div className="boxes">
                <Link
                    to="/people/facultydetails"
                    style={{ textDecoration: "none", color: "inherit" }}
                    className="box"
                >
                    <div>
                        <img src={image1} alt="faculty1" />
                        <div>
                            <h3>XYZ</h3>
                            <p>Assistant Professor</p>
                            <p>
                                <span>&#128222;</span>1234567890
                            </p>
                            <p>
                                <span>&#128231;</span>xyz@gmail.com
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>Research Area:</b> Quantum Physics
                    </p>
                </Link>
                <div className="box">
                    <div>
                        <img src={image2} alt="faculty2" />
                        <div>
                            <h3>XYZ</h3>
                            <p>Assistant Professor</p>
                            <p>
                                <span>&#128222;</span>1234567890
                            </p>
                            <p>
                                <span>&#128231;</span>xyz@gmail.com
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>Research Area:</b> Quantum Physics
                    </p>
                </div>
                <div className="box">
                    <div>
                        <img src={image1} alt="faculty3" />
                        <div>
                            <h3>XYZ</h3>
                            <p>Assistant Professor</p>
                            <p>
                                <span>&#128222;</span>1234567890
                            </p>
                            <p>
                                <span>&#128231;</span>xyz@gmail.com
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>Research Area:</b> Quantum Physics
                    </p>
                </div>
                <div className="box">
                    <div>
                        <img src={image2} alt="faculty4" />
                        <div>
                            <h3>XYZ</h3>
                            <p>Assistant Professor</p>
                            <p>
                                <span>&#128222;</span>1234567890
                            </p>
                            <p>
                                <span>&#128231;</span>xyz@gmail.com
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>Research Area:</b> Quantum Physics
                    </p>
                </div>
            </div>
            <p className="fixed-text">
                physics departmental strength = our faculties
            </p>
        </div>
    );
}

export default Faculty;
