import React from "react";
import {Link} from "react-router-dom";

const Content = () => {
    return (
        <>
            <section className="relative bg-gradient-to-r from-violet-50 to-orange-50">
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <div className="grid grid-cols-1 items-center gap-8 sm:gap-20 lg:grid-cols-2">
                        <div className="max-w-[720px]">
                            <h1 className="mb-3 pb-4 text-4xl font-bold text-[#281981] md:text-6xl">
                                Dedicated to the service of the Nation
                            </h1>
                            <p className="mb-6 text-black max-w-[528px] text-xl md:mb-10">
                                The motto of IIT Kharagpur is "योगः कर्मसु
                                कौशलम्". This literally translates to
                                "Excellence in action is Yoga", essentially
                                implying that doing your work well is (true)
                                yoga. This can be traced to Sri Krishna's
                                discourse with Arjuna in the Bhagavad Gita. The
                                quote, in the larger context of the Gita, urges
                                man to acquire equanimity because a mind of
                                equanimity allows a man to shed distracting
                                thoughts of the effects of his deeds and
                                concentrate on the task before him. Equanimity
                                is the source of perfection in Karmic endeavours
                                that leads to Salvation.{" "}
                            </p>
                            <div className="flex items-center">
                                <Link
                                    to="#"
                                    className="mr-5 inline-block rounded-full bg-[#281981] px-6 py-2 text-center font-bold text-white transition hover:border-black hover:bg-[#ECB22E] md:mr-6 lg:mr-8"
                                >
                                    Let's Talk
                                </Link>
                                <Link
                                    to="#"
                                    className="flex max-w-full flex-row rounded-full border border-solid border-[#636262] px-6 py-2 font-bold"
                                >
                                    View Showreel
                                </Link>
                            </div>
                        </div>

                        <div className="image-container mx-auto w-full max-w-[640px]">
                            <img
                                src="https://physics.du.ac.in/images/img5-0.jpg"
                                alt="Showreel"
                                className="h-[500px] w-full rounded-2xl object-cover"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Content;
