import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Faculty from "./components/people/Faculty";
import Researchers from "./components/people/Researchers";
import TechnicalStaff from "./components/people/TechnicalStaff";
import AdministrativeStaff from "./components/people/AdministrativeStaff";
import Students from "./components/people/Students";
import FacultyDetails from "./components/people/FacultyDetails";
import TemporaryJobs from "./components/temporaryjobs/TemporaryJobs";
import NonTeachingPositions from "./components/temporaryjobs/NonTeachingPositions";

import Interp from "./components/academic/Interp";
import Contactas from "./components/academic/Contactas";
import Academic from "./components/academic/Academic";
import Relation from "./components/academic/Relation";
import Programs from "./components/academic/Programs";

import Units from "./components/academic/Units";
import Curriculatemplate from "./components/curriculum/Curriculatemplate";

import History from "./components/Boxsection/History";
import ProfDetail from "./components/Boxsection/ProfDetail";
import Research from "./components/Boxsection/Research";
import Phdpage from "./components/all/Phdpage";
import PhdpageSub from "./components/Boxsection/PhdpageSub";
import SeminarPage from "./components/all/Seminar";
import Giving from "./components/all/Giving";
import Announcement from "./components/all/Announcement";
import Director from "./components/all/Director";

import News from "./components/news/News";

import Contact from "./components/layout/contact/Contact";
import Nav from "./components/layout/Nav";
import Footer from "./components/layout/Footer";
import Home from "./components/layout/Home";
import Events from "./components/all/Events";
import About from "./components/all/About";
import SubNews from "./components/news/SubNews";
import Alumini from "./components/all/Alumini";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    return (
        <div className="App">
            <Router>
                <Nav />
                <ScrollToTop />
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/contact" exact element={<Contact />} />
                    <Route path="/aboutus" exact element={<About />} />
                    

                    <Route path="/people/faculty" exact element={<Faculty />} />
                    <Route
                        path="/people/researchers"
                        exact
                        element={<Researchers />}
                    />
                    <Route
                        path="/people/students"
                        exact
                        element={<Students />}
                    />
                    <Route
                        path="/people/technical"
                        exact
                        element={<TechnicalStaff />}
                    />
                    <Route
                        path="/people/administration"
                        exact
                        element={<AdministrativeStaff />}
                    />
                    <Route
                        path="/people/facultydetails"
                        exact
                        element={<FacultyDetails />}
                    />

                    <Route path="/academic" exact element={<Academic />} />
                    <Route
                        path="/academic/interdisciplinary"
                        exact
                        element={<Interp />}
                    />
                    <Route
                        path="/academic/international"
                        exact
                        element={<Relation />}
                    />
                    <Route
                        path="/academic/contact"
                        exact
                        element={<Contactas />}
                    />
                    <Route
                        path="/academic/programs"
                        exact
                        element={<Programs />}
                    />
                    <Route path="/academic/units" exact element={<Units />} />
                    <Route
                        path="/curriculum"
                        exact
                        element={<Curriculatemplate />}
                    />
                    <Route path="/events" exact element={<Events />}/>

                    <Route path="/aboutus/history" exact element={<History />} />
                    <Route path="/research" exact element={<Research />} />
                    <Route path="/profdetail" exact element={<ProfDetail />} />
                    <Route path="/phdpage" exact element={<Phdpage />} />
                    <Route path="/phdpage/phdpagesub" exact element={<PhdpageSub />} />
                    <Route path="/seminar" exact element={<SeminarPage />} />
                    <Route path="/giving" exact element={<Giving />} />
                    <Route
                        path="/announcement"
                        exact
                        element={<Announcement />}
                    />
                    <Route path="/director" exact element={<Director />} />

                    <Route path="/news" exact element={<News />} />
                    <Route path="/news/subnews" exact element={<SubNews />} />
                    <Route path="/alumini" exact element={<Alumini />} />
                </Routes>
                <Footer />
            </Router>
            {/* <Faculty /> */}
            {/* <Researchers /> */}
            {/* <Students /> */}
            {/* <TechnicalStaff /> */}
            {/* <AdministrativeStaff /> */}
            {/* <FacultyDetails /> */}
            {/* <TemporaryJobs /> */}
            {/* <NonTeachingPositions /> */}
        </div>
    );
}

export default App;
