import React from "react";
import '../../people.css';
import { Link } from "react-router-dom";
import image1 from '../../assets/admin1.jpg';
import image2 from '../../assets/technical2.jpg';

function AdministrativeStaff() {
    return (
        <div className="administrative-staff">
            {/* <header>
                <div>
                    <h1>Meet Our Administrative Staffs</h1>
                </div>
                <button className="home-link"><Link to='/'>Home</Link></button>
            </header> */}
            <div className="people-hero-section">
                <img
                    src="https://plus.unsplash.com/premium_photo-1661578772704-f4ecbbe15802?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="hero"
                />
                <h1>Administrative Staff</h1>
            </div>
            <h1 className="main-head" style={{marginTop:"1rem"}}><span style={{fontSize:"40px"}}>|</span>Administrative Staff</h1>
            <div className="administrative-team">
                <div>
                    <img src={image1} alt="admin-staff1"/>
                    <div className="content-box">
                        <p>Name: XYZ</p>
                        <p>Role: Manager</p>
                        <p>Contact: 123456789</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Location: London</p>
                    </div>
                </div>
                <div>
                    <img src={image2} alt="admin-staff2"/>
                    <div className="content-box">
                        <p>Name: XYZ</p>
                        <p>Role: Manager</p>
                        <p>Contact: 123456789</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Location: London</p>
                    </div>
                </div>
            </div>
            <h1 className="main-head" style={{marginTop:"2rem"}}><span style={{fontSize:"40px"}}>|</span>Quick Links</h1>
            <div className="quick-links">
                <button type="button">Board of Governers</button>
                <button type="button">Finance Committee</button>
                <button type="button">Deans' Institute</button>
                <button type="button">Heads</button>
                <button type="button">Presidents,PICs & Chairperson</button>
                <button type="button">Building and Works Committee</button>
                <button type="button">Wardens</button>
                <button type="button">Assistant Wardens</button>
                <button type="button">JR/DR/AR</button>
                <button type="button">Administrative Units</button>
            </div>
        </div>
    );
}

export default AdministrativeStaff;
