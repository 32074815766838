import React from "react";
import '../../people.css';
import { Link } from "react-router-dom";
import technical1 from '../../assets/technical1.jpg';
import technical2 from '../../assets/technical2.jpg';
import technical3 from '../../assets/technical3.jpg';
import technical4 from '../../assets/technical4.jpg';
import technical5 from '../../assets/technical5.jpg';
import technical6 from '../../assets/technical6.jpg';
import technical7 from '../../assets/technical7.jpg';
import technical8 from '../../assets/technical8.jpg';
function TechnicalStaff() {
    return (
        <div className="technical-staff">
            {/* <header>
                <div>
                    <h1>Meet Our Technical Team</h1>
                </div>
                <button className="home-link"><Link to='/'>Home</Link></button>
            </header> */}
            <div className="people-hero-section">
                <img
                    src="https://images.unsplash.com/photo-1551135049-8a33b5883817?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="hero"
                />
                <h1>Technical Staff</h1>
            </div>
            <h1 className="main-head" style={{marginTop:"1rem"}}><span style={{fontSize:"40px"}}>|</span>Technical Staff</h1>
            <div className="technical-team">
                <div>
                    <div className="image-div">
                        <img src={technical1} alt="technical-member1"/>
                        <div>
                            <h3>Paul</h3>
                            <h3>HOD</h3>
                        </div>
                    </div>
                    <div className="content-div">
                        <p>Responsibilities:</p>
                        <p>Contact: 1234567890</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Office-Location: Bangalore</p>
                    </div>
                </div>
                <div>
                    <div className="image-div">
                        <img src={technical2} alt="technical-member2"/>
                        <div>
                            <h3>Meera</h3>
                            <h3>Assistance Professor</h3>
                        </div>
                    </div>
                    <div className="content-div">
                        <p>Responsibilities:</p>
                        <p>Contact: 1234567890</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Office-Location: Bangalore</p>
                    </div>
                </div>
                <div>
                    <div className="image-div">
                        <img src={technical3} alt="technical-member3"/>
                        <div>
                            <h3>Sheela</h3>
                            <h3>Senior Professor</h3>
                        </div>
                    </div>
                    <div className="content-div">
                        <p>Responsibilities:</p>
                        <p>Contact: 1234567890</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Office-Location: Bangalore</p>
                    </div>
                </div>
                <div>
                    <div className="image-div">
                        <img src={technical4} alt="technical-member4"/>
                        <div>
                            <h3>Mery</h3>
                            <h3>Professor</h3>
                        </div>
                    </div>
                    <div className="content-div">
                        <p>Responsibilities:</p>
                        <p>Contact: 1234567890</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Office-Location: Bangalore</p>
                    </div>
                </div>
                <div>
                    <div className="image-div">
                        <img src={technical5} alt="technical-member5"/>
                        <div>
                            <h3>Mark</h3>
                            <h3>Associate Professor</h3>
                        </div>
                    </div>
                    <div className="content-div">
                        <p>Responsibilities:</p>
                        <p>Contact: 1234567890</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Office-Location: Bangalore</p>
                    </div>
                </div>
                <div>
                    <div className="image-div">
                        <img src={technical6} alt="technical-member6"/>
                        <div>
                            <h3>Neelum</h3>
                            <h3>Professor</h3>
                        </div>
                    </div>
                    <div className="content-div">
                        <p>Responsibilities:</p>
                        <p>Contact: 1234567890</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Office-Location: Bangalore</p>
                    </div>
                </div>
                <div>
                    <div className="image-div">
                        <img src={technical7} alt="technical-member7"/>
                        <div>
                            <h3>John</h3>
                            <h3>Senior Professor</h3>
                        </div>
                    </div>
                    <div className="content-div">
                        <p>Responsibilities:</p>
                        <p>Contact: 1234567890</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Office-Location: Bangalore</p>
                    </div>
                </div>
                <div>
                    <div className="image-div">
                        <img src={technical8} alt="technical-member8"/>
                        <div>
                            <h3>Akul</h3>
                            <h3>Lab Technician</h3>
                        </div>
                    </div>
                    <div className="content-div">
                        <p>Responsibilities:</p>
                        <p>Contact: 1234567890</p>
                        <p>Email: xyz@gmail.com</p>
                        <p>Office-Location: Bangalore</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TechnicalStaff;
