import React from "react";
import "../../people.css";
import image from "../../assets/facultydetails.png";
import { Link } from "react-router-dom";

function FacultyDetails() {
    return (
        <div className="faculty-details">
            {/* <header>
                <div>
                    <h1>
                        I'm John Marker <br /> Assistance Professor
                    </h1>
                </div>
                <button className="home-link">Home</button>
            </header> */}
            <div className="faculty-data">
                <div className="faculty-bio">
                    <img src={image} alt="Faculty details" />
                    <div>
                        <p>
                            <b>Contact:</b> 1234567890
                        </p>
                        <p>
                            <b>Email:</b> xyz@gmail.com
                        </p>
                        <p>
                            <b>Location:</b> Bangalore
                        </p>
                        <p>
                            <b>Linkedin:</b> www.linkedin.com
                        </p>
                    </div>
                    <div>
                        <p>
                            <i className="bi bi-box-arrow-up-right"> </i>Awards
                            and Accolades
                        </p>
                        <p>
                            <i className="bi bi-globe"> </i>Personal Webpage
                        </p>
                        <p>
                            <i className="bi bi-file-earmark-arrow-down"> </i>
                            Bio Sketch
                        </p>
                    </div>
                </div>
                <div className="faculty-description">
                    <header style={{ marginBottom: "1rem" }}>
                        <div>
                            <h1 className="goback-link">
                                <Link to="/people/faculty">
                                    <i
                                        class="bi bi-arrow-left"
                                        style={{ marginRight: ".5rem" }}
                                    ></i>
                                    Go back
                                </Link>
                            </h1>
                            <h1>
                                I'm John Marker <br /> Assistance Professor
                            </h1>
                        </div>
                    </header>
                    <h2>
                        <span>| </span>Responsibilities
                    </h2>
                    <div className="responsibilities">
                        <p>
                            <i className="bi bi-check-circle"> </i> Caretaker
                            NCC
                        </p>
                        <p>
                            <i className="bi bi-check-circle"> </i> Assistant
                            Warden
                        </p>
                    </div>
                    <h2>
                        <span>| </span>Research Areas
                    </h2>
                    <div className="research-areas">
                        <p>
                            <i className="bi bi-check-circle"></i> Quantum
                            Physics
                        </p>
                        <p>
                            <i className="bi bi-check-circle"></i> Particle
                            Physics
                        </p>
                        <p>
                            <i className="bi bi-check-circle"></i> Aircraft
                            Structures{" "}
                        </p>
                        <p>
                            <i className="bi bi-check-circle"></i> Elector
                            Magnetics{" "}
                        </p>
                    </div>
                    <img
                        src="https://images.pexels.com/photos/5819906/pexels-photo-5819906.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                        alt="research-area-image"
                    />
                    <div>
                        <div
                            className="accordion"
                            id="accordionPanelsStayOpenExample"
                        >
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                    >
                                        <i
                                            className="bi bi-search"
                                            style={{ marginRight: ".5rem" }}
                                        >
                                            {" "}
                                        </i>
                                        Research Statement
                                    </button>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseOne"
                                    className="accordion-collapse collapse show"
                                >
                                    <div className="accordion-body">
                                        <p>
                                            I am interested in developing and
                                            using existing CAE solvers to tackle
                                            industrial problems. I am also
                                            interested in modeling instabilities
                                            in fluid flows. My group has gained
                                            expertiese in CFD simulations using
                                            ANSYS FLuent, OpenFOAM (various
                                            solvers) and development of inhouse
                                            codes on Lattice Boltzmann Methods
                                            (for GPU parallel computations) and
                                            traditional ROE based compressible
                                            sovler (for CPU based parallel
                                            computations). I am open to explore
                                            problem with multi-physics
                                            interations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpen-collapseTwo"
                                    >
                                        <i
                                            className="bi bi-file-earmark-text"
                                            style={{ marginRight: ".5rem" }}
                                        >
                                            {" "}
                                        </i>
                                        Selected Publications
                                    </button>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseTwo"
                                    className="accordion-collapse collapse"
                                >
                                    <div className="accordion-body">
                                        <button
                                            className="btn btn-primary"
                                            style={{
                                                position: "absolute",
                                                right: "3rem",
                                            }}
                                        >
                                            All Publications
                                        </button>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "1rem",
                                                marginTop: "3rem",
                                            }}
                                        >
                                            <i className="bi bi-check-circle"></i>{" "}
                                            <p>
                                                Secondary subharmonic
                                                instability of hypersonic
                                                boundary layer in thermochemical
                                                equilibrium over a flat plate
                                                Kumar C., Prakash A. By Physics
                                                of Fluids 33 - (2021)
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "1rem",
                                            }}
                                        >
                                            <i className="bi bi-check-circle"></i>{" "}
                                            <p>
                                                A comparative study of
                                                bounce-back and immersed
                                                boundary method in LBM for
                                                turbulent flow simulation
                                                Agarwal A., Prakash A. By
                                                Materials Today 28 - (2020)
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "1rem",
                                            }}
                                        >
                                            <i className="bi bi-check-circle"></i>{" "}
                                            <p>
                                                Nonlinear interaction among
                                                second mode resonance waves in
                                                high-speed boundary layers using
                                                the method of multiple scales
                                                Kumar C., Prakash A. By Physics
                                                of Fluids 34 014107- (2022)
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "1rem",
                                            }}
                                        >
                                            <i className="bi bi-check-circle"></i>{" "}
                                            <p>
                                                High-Order Shock Fitting Methods
                                                for Simulation of Hypersonic
                                                Flow with Chemical and Thermal
                                                Nonequilibrium Prakash A.,
                                                Parsons N. , Wang X. , Zhong X.
                                                By Journal of Computational
                                                Physics 230 - (2011)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpen-collapseThree"
                                    >
                                        <i
                                            className="bi bi-list-task"
                                            style={{ marginRight: ".5rem" }}
                                        >
                                            {" "}
                                        </i>
                                        Current Projects
                                    </button>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseThree"
                                    className="accordion-collapse collapse"
                                >
                                    <div className="accordion-body">
                                        <button
                                            className="btn btn-primary"
                                            style={{
                                                position: "absolute",
                                                right: "3rem",
                                            }}
                                        >
                                            All Publications
                                        </button>
                                        <div style={{ marginTop: "3rem" }}>
                                            <h2>Principal Investigator</h2>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "1rem",
                                                }}
                                            >
                                                <i className="bi bi-check-circle">
                                                    {" "}
                                                </i>
                                                <p>
                                                    Numerical Investigation of
                                                    the Effect of Fuel Injection
                                                    Scheme on the Performance of
                                                    SCRAM Jet Combustor KCSTC
                                                </p>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "1rem",
                                                }}
                                            >
                                                <i className="bi bi-check-circle">
                                                    {" "}
                                                </i>
                                                <p>
                                                    Numerical Investigation of
                                                    the Effect of Fuel Injection
                                                    Scheme on the Performance of
                                                    SCRAM Jet Combustor ISRO,
                                                    INDIA
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <h2>Co-Principal Investigation</h2>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "1rem",
                                                }}
                                            >
                                                <i className="bi bi-check-circle">
                                                    {" "}
                                                </i>
                                                <p>
                                                    Aero-Thermo-Elastic Analysis
                                                    of Hypersonic Vehicle{" "}
                                                    <b>
                                                        Aeronautics R and D
                                                        Board
                                                    </b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseFour"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpen-collapseFour"
                                    >
                                        <i
                                            className="bi bi-people-fill"
                                            style={{ marginRight: ".5rem" }}
                                        >
                                            {" "}
                                        </i>{" "}
                                        Group Members
                                    </button>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseFour"
                                    className="accordion-collapse collapse"
                                >
                                    <div className="accordion-body">
                                        <button
                                            className="btn btn-primary"
                                            style={{
                                                position: "absolute",
                                                right: "3rem",
                                            }}
                                        >
                                            Alumini Members
                                        </button>
                                        <h2
                                            style={{
                                                textAlign: "center",
                                                textDecoration: "underline",
                                                marginTop: "3rem",
                                            }}
                                        >
                                            Ph.D. Students
                                        </h2>
                                        <div className="accordion-div">
                                            <div>
                                                <h3>Animesh Bowmik</h3>
                                                <p>
                                                    <b>Area of Research:</b>{" "}
                                                    Fluid Dynamics
                                                </p>
                                            </div>
                                            <div>
                                                <h3>Shambhu Kumar Tati</h3>
                                                <p>
                                                    <b>Area of Research:</b>{" "}
                                                    Fluid Mechanics
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FacultyDetails;
