import React from "react";
import "../../people.css";
import { Link } from "react-router-dom";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";

function Researchers() {
    return (
        <div className="researchers">
            <div className="people-hero-section">
                <img
                    src="https://plus.unsplash.com/premium_photo-1661438058994-d13fec8543f8?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="hero"
                />
                <h1>Researchers</h1>
            </div>
            <h1
                className="main-head"
                style={{ marginLeft: "1rem", marginTop: "1rem" }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Researchers
            </h1>
            <div className="boxes">
                <Link
                    to="/people/facultydetails"
                    style={{ textDecoration: "none", color: "inherit" }}
                    className="box"
                >
                    <div>
                        <img src={image1} alt="researcher1" />
                        <div>
                            <h3>XYZ</h3>
                            <p>Assistant Professor</p>
                            <p>
                                <span>&#128222;</span>1234567890
                            </p>
                            <p>
                                <span>&#128231;</span>xyz@gmail.com
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>Research Area:</b> Quantum Physics
                    </p>
                </Link>
                <div className="box">
                    <div>
                        <img src={image2} alt="researcher2" />
                        <div>
                            <h3>XYZ</h3>
                            <p>Assistant Professor</p>
                            <p>
                                <span>&#128222;</span>1234567890
                            </p>
                            <p>
                                <span>&#128231;</span>xyz@gmail.com
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>Research Area:</b> Quantum Physics
                    </p>
                </div>
                <div className="box">
                    <div>
                        <img src={image2} alt="researcher3" />
                        <div>
                            <h3>XYZ</h3>
                            <p>Assistant Professor</p>
                            <p>
                                <span>&#128222;</span>1234567890
                            </p>
                            <p>
                                <span>&#128231;</span>xyz@gmail.com
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>Research Area:</b> Quantum Physics
                    </p>
                </div>
                <div className="box">
                    <div>
                        <img src={image1} alt="researcher4" />
                        <div>
                            <h3>XYZ</h3>
                            <p>Assistant Professor</p>
                            <p>
                                <span>&#128222;</span>1234567890
                            </p>
                            <p>
                                <span>&#128231;</span>xyz@gmail.com
                            </p>
                        </div>
                    </div>
                    <p>
                        <b>Research Area:</b> Quantum Physics
                    </p>
                </div>
            </div>
            <h1
                className="main-head"
                style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Research &
                Development
            </h1>
            <div className="research">
                <p>
                    Research and Development (R&D) at IIT Kharagpur has evolved
                    and flourished over the decades since the Institute's
                    inception in 1958. The synergy of academics and research has
                    catapulted the Institute into the illustrious circle of
                    world-class institutions. Apart from offering viable
                    solutions to various government sectors, industry, and to
                    society, IIT Kharagpur pursues basic research leading to
                    knowledge generation that lays the foundation for empowering
                    India as a nation to be technologically confident and
                    self-reliant.
                </p>
                <p>
                    The Institute continues to strive for excellence in its core
                    activities of teaching and research in a milieu of change.
                    The Institute has been able to attract outstanding faculty
                    members from not just India but other parts of the globe. We
                    have set up several large multidisciplinary research centers
                    to address complex problems in a holistic way involving
                    researchers from different academic units.
                </p>
                <p>
                    IIT Kharagpur has ongoing interactions with many industries
                    and public sector organizations through various modes,
                    including providing solutions to specific problems through
                    short, medium to long-term research projects, endowments,
                    student sponsorship, etc.
                </p>
                <p>
                    The goal is to promote research that makes a difference - a
                    difference to society, to industry, and to the profession
                    itself.
                </p>
                <div>
                    <h2>Research Park</h2>
                    <p>
                        Brings Industry R&D centers closer to the intellectual
                        hub of academia by providing space and infrastructure on
                        IITKGP campus. Industries can engage with researchers on
                        exciting research areas, get access to IITKGP research
                        infrastructure, library, and much more.
                    </p>
                    <button type="button">Know more</button>
                </div>
                <div>
                    <h2>Technology transfer</h2>
                    <p>
                        Research and development being the increasing focus of
                        activity, the Institute is making strong efforts to see
                        that the fruits of the research are translated into
                        commerce through licensing and startups. More than 100
                        technologies and products developed at IIT Kharagpur
                        have been deployed. Some of these technologies are ready
                        for implementation, while others may need additional
                        work to reach the deployment stage: prototyping, mass
                        production, balancing the cost, and quality of the
                        product, etc. These efforts require collaboration
                        between IIT Kharagpur and the Licensee at the initial
                        stages. We will be happy to partner with entities for
                        translating our technologies to end use.
                    </p>
                    <button type="button">
                        Technology transfer and Licensing
                    </button>
                </div>
                <div>
                    <h2>Entrepreneurship</h2>
                    <p>
                        Society for Innovation and Entrepreneurship (SINE), is
                        an umbrella organization at IIT Kharagpur for fostering
                        entrepreneurship and nurturing tech start-ups. It
                        administers a business incubator which provides ‘Start
                        to scale’ support for technology-based entrepreneurship
                        and facilitates the conversion of research activity into
                        entrepreneurial ventures.
                    </p>
                    <button type="button">Know more</button>
                </div>
            </div>
            <h1
                className="main-head"
                style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Central Workshop and
                Instruments Service Section (CWISS)
            </h1>
            <div className="research">
                <p>
                    Research and development endeavours are making roadways
                    towards a better sustenance of the society. Skilful
                    fabrication based on modern technologies are of utmost
                    important for research organisations, laboratories and
                    industries. At the same time, an educational training to
                    share and impart knowledge is emphasized for innovation,
                    social awareness, and professional ethics. The Central
                    Workshop and Instruments Service Section (CWISS) of IIT
                    Kharagpur is a unique service centre, since its inception
                    from 1965, this section provides the fabrication of custom
                    made instruments to sustain the Post Graduate and Research
                    activities in the Institute apart from executing regular
                    work orders from various Department/Centre/Section of the
                    Institute for day to day educational activities, CWISS also
                    undertakes work orders from outside educational institutes
                    and industries on payment basis. CWISS also provides
                    in-campus and off-campus training on different design and
                    manufacturing software. CWISS also provides regular training
                    on operation of various CNC Machines.
                </p>
                <a href="#">Click here for more details</a>
            </div>
            <h1
                className="main-head"
                style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>IP Policies
            </h1>
            <div className="research">
                <p>
                    IIT Kharagpur is well known for its research oriented
                    culture. This has led to development of various new
                    technologies. Till date there are about 450 Patents filed of
                    which 120 already granted and 7 licensed, about 40
                    Copyrights and close to 60 technology transfers.
                </p>
                <p>
                    This activity is managed under the Intellectual Property
                    Rights and Industrial Relations (IPR & IR) Cell. The cell is
                    responsible for the licensing and the transfer of
                    technologies developed by researchers at IIT Kharagpur to
                    the commercial sector.
                </p>
                <p>
                    The Cell from time to time organizes Intellectual Property
                    Drive. The students' team under this Cell, Technology
                    Transfer Group (TTG) also develop awareness among student
                    community where they can generate and protect Intellectual
                    Property out of their exploits in academic projects. The IPR
                    activity of IIT Kharagpur drew attention of a leading
                    magazine, Career360.{" "}
                </p>
                <p>
                    <b>IPR Policy:</b> The IPR policy is reviewed internally and
                    with stakeholders. The last internal review was conducted in
                    2014-15 and updated policy will be published by end of
                    calendar year 2015 after accommodating suggestions from
                    stakeholders.
                </p>
                <h3>Some of our key IP clients:</h3>
                <p>
                    Emami, TELCO, Mahindra & Mahindra, Coal India, RBI, Tata
                    Metallics.
                </p>
            </div>
            <h1
                className="main-head"
                style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Research Facilities
            </h1>
            <div className="research-accordion">
                <p>
                    IIT Kharagpur is widely acclaimed for the quality and
                    breadth of its research enterprise, and particularly for its
                    openness to multidisciplinary research. Several highly rated
                    initiatives represent a long IIT Kharagpur tradition of
                    cross-disciplinary research and collaboration.
                </p>
                <p>
                    In this continuous quest for excellence in our research
                    endeavours, we recognize the importance of modernization in
                    infrastructure and experimental facilities. To ensure that
                    we hold on to our position at the cutting edge of
                    innovation, the Institute has set up several state of the
                    art facilities.
                </p>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed t"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseOne"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseOne"
                            >
                                Advanced VLSI Laboratory
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    Founded in the year 2000 with the active
                                    support of the IIT Foundation and several
                                    companies, the Advanced VLSI Design
                                    Laboratory is a state-of-the-art centre for
                                    advanced research in VLSI Design, Test and
                                    CAD.
                                </p>
                                <p>
                                    The lab has established industry standard
                                    design and verification flows. The AVLSI Lab
                                    has its own state-of-the-art test equipment
                                    procured with institute support. More than
                                    100 chips have been designed in this lab,
                                    fabricated and successfully tested.
                                </p>
                                <p>
                                    Currently more than 60 PhD students and full
                                    time MS students work under the ambit of the
                                    lab. Each year about 70-80 papers are
                                    published in areas related to VLSI design
                                    and CAD by the students and faculty
                                    associated with the activities of the Lab.
                                    There are several research groups in the
                                    lab, who have specific areas of expertise,
                                    such as Digital Design, Analog Design, RFIC,
                                    Power Management, Test, Verification & CAD,
                                    and MEMS.
                                </p>
                                <p>
                                    In recent times several vertical domains are
                                    emerging which exploit the expertise of
                                    multiple research groups. This includes
                                    application-specific Sensor-Nodes, Medical
                                    Electronics, Automotive Electronics,
                                    Software Radio, Mobile and Wireless
                                    Computing and next generation Semiconductor
                                    Devices.
                                </p>
                                <p>
                                    <b>Contact:</b> Prof. Tarun Kanti
                                    Bhattacharya{" "}
                                    <a href="mailto:tkb@ece.iitkgp.ac.in">
                                        tkb@ece.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTwo"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTwo"
                            >
                                Centre for Railway Research
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <div>
                                    <p>
                                        The Centre for Railway Research (CRR) is
                                        a collaborative venture between IIT
                                        Kharagpur and the Indian Railways to
                                        develop a long-term framework for
                                        research aimed at driving significant
                                        advancements in the field of Railway
                                        Technology and productive utilisation of
                                        the rail infrastructure. The thrust
                                        areas of research in the Centre are
                                        Advanced Materials and Manufacturing,
                                        Heavy Haul Technology, High Speed Rail
                                        and Advanced Maintenance and Operations.
                                    </p>
                                    <p>
                                        For more on the Centre and its
                                        operations, Click Here.
                                    </p>
                                    <p>
                                        <b>Contact:</b> Prof. Subhransu Roy at{" "}
                                        <a href="mailto:suroy@mech.iitkgp.ac.in">
                                            suroy@mech.iitkgp.ac.in
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseThree"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseThree"
                            >
                                Central Research Facility
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseThree"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    The Central Research Facility is an
                                    integrated facility for all students at IIT
                                    Kharagpur to carry out their research
                                    activities centrally under one umbrella.
                                    This facility has two broad Divisions:
                                    Materials Science Division and Life Science
                                    Division. There are around 36 laboratories
                                    in the CRF complex and each laboratory is
                                    under the supervision of a designated
                                    faculty member from the Institute.
                                </p>
                                <p>
                                    The equipments housed in these laboratories
                                    are used for various types of
                                    characterization including study of
                                    structure and chemical composition of
                                    surfaces and bulk materials at different
                                    length scales (sub-nanometer to millimeter),
                                    phase transitions, as well as evaluation of
                                    mechanical, electrical, magnetic, and
                                    optical properties. The available facilities
                                    for such studies on materials include
                                    state-of-the art field emission scanning and
                                    transmission electron microscopes, dual beam
                                    FIB-FEG microscopes, X-ray diffractometers,
                                    X-Ray Micro-CT, Scanning Auger Nanoprobe,
                                    Atomic Force Microscope, Nano-triboindenter,
                                    Raman Spectrometer, Thermal Analyzers,
                                    SQUID-VSM, Hall-effect measurement, etc.
                                    Various cells and biomolecules (DNA and
                                    proteins) are also studied for their
                                    structural analysis and interactions using
                                    high end equipment like MALDI, X-ray, ITC,
                                    FACS etc. Students from the different
                                    branches of science and engineering come
                                    here for research and experimentation,
                                    creating a vibrant cross-disciplinary
                                    atmosphere.
                                </p>
                                <p>
                                    For more details on the Centre, Click Here.
                                </p>
                                <p>
                                    <b>Contact:</b> Chairman{" "}
                                    <a href="mailto:chairman@iitkgp.ac.in">
                                        chairman@iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseFour"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFour"
                            >
                                Centre for Theoretical Studies
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseFour"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    The Centre for Theoretical Studies (CTS) was
                                    set up in 1998 in a part of the old building
                                    of IIT Kharagpur. Its primary goal is to
                                    generate and nucleate theoretical research
                                    on fundamental aspects of basic and
                                    engineering sciences. The role of the CTS in
                                    the academic framework of IIT Kharagpur is
                                    to bring together faculty and students with
                                    similar interests under a common umbrella.
                                </p>
                                <p>
                                    The CTS, apart from acting as a facility for
                                    research in theoretical and computational
                                    aspects of science and engineering, trains
                                    graduate students and offers graduate-level
                                    courses. It also providesopportunities to
                                    post doctoral workers and researchers from
                                    outside IIT Kharagpur through an active
                                    visitors programme.
                                </p>
                                <p>
                                    The Centre organizes seminars on very
                                    diverse topics by visiting experts workshops
                                    and conferences on topics of current
                                    interest. The CTS considers the cultivation
                                    of inter-disciplinary theoretical research
                                    as a major goal largely because of the
                                    diversity available in the academic
                                    population of IIT Kharagpur.
                                </p>
                                <p>
                                    For more on the CTS and its operations,
                                    Click Here..
                                </p>
                                <p>
                                    <b>Contact:</b> Convenor, CTS{" "}
                                    <a href="mailto:convenor@cts.iitkgp.ac.in">
                                        convenor@cts.iitkgp.ac.in
                                    </a>{" "}
                                    ; Prof. Sayan Kar{" "}
                                    <a href="mailto:sayan@phy.iitkgp.ac.in">
                                        sayan@phy.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseFive"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFive"
                            >
                                Computer and Informatics Centre
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseFive"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    The Computer and Informatics Centre at IIT
                                    Kharagpur is responsible for planning,
                                    deployment, and maintenance of the core
                                    computing and network infrastructure of the
                                    institute. The Centre supports wired and
                                    wireless network services spanning the
                                    entire institute campus including all
                                    academic areas, halls of residences, faculty
                                    and staff residences, and other ancillary
                                    units. It is also responsible for providing
                                    other infrastructure support such as a
                                    central email system, access to common
                                    software packages, web hosting, and large
                                    computing labs for supporting different
                                    institute courses. The Centre is housed in
                                    the ground floor of Takshashila building
                                    inside the IIT campus.
                                </p>
                                <p>
                                    For more details on the Centre, Click Here
                                </p>
                                <p>
                                    <b>Contact:</b> Head{" "}
                                    <a href="mailto:head@cc.iitkgp.ac.in">
                                        head@cc.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseSix"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseSix"
                            >
                                Kalpana Chawla Space Technology Cell (KCSTC)
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseSix"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    The activities of Space Technology Cell was
                                    initiated in 1998 in Indian Institute of
                                    Technology, Kharagpur with an aim to work in
                                    the areas of MEMS, Communications and
                                    Cryogenics including Engine Modeling.
                                    Subsequently the areas of materials, control
                                    systems, micro propulsions and VLSI design
                                    were included. The communications area
                                    extended to the areas of RF design,
                                    Electromagnetics, EMI/EMC and ESD. Starting
                                    with faculty members of the Departments of
                                    Aerospace Engineering, Chemical Engineering,
                                    Electronics & Electrical Communication
                                    Engineering, Electrical Engineering,
                                    Computer Science and Engineering, Cryogenic
                                    Engineering, Mechanical Engineering,
                                    Metallurgical & Materials Engineering,
                                    Rubber Technology Centre, Advanced
                                    Technology Centre, G. S Sanyal School of
                                    Telecommunication, Mining Engineering and
                                    School of Medical Science & Technology.
                                </p>
                                <p>
                                    For more information visit KCSTC Homepage ,
                                    Click Here
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseSeven"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseSeven"
                            >
                                Membrane Separation Laboratory
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseSeven"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    Researchers at the membrane separation
                                    laboratory IIT Kharagpur, are actively
                                    engaged in a variety of challenging problems
                                    ranging from drinking water purification to
                                    dialysis, hollow fiber spinning to
                                    mathematical modelling of transport
                                    processes. The research group can be broadly
                                    categorized into following five verticals:
                                    (i) Drinking water purification
                                    technologies, (ii) Low cost Industrial Waste
                                    Water Technologies, (iii) Affordable Health
                                    Care Device Development Technologies, (iv)
                                    Fruit juice processing technologies and (v)
                                    Mathematical and Computational Modelling of
                                    Transport Processes.
                                </p>
                                <p>
                                    The membrane lab research activities involve
                                    casting of flat sheet and spinning of hollow
                                    fiber membranes of various grades ranging
                                    from nanofiltration, ultrafiltration, to
                                    microfiltration including dialysis. The
                                    material can be polymeric, blend polymeric
                                    systems, mixed matrix membranes.
                                </p>
                                <p>
                                    The laboratory is well equipped with
                                    analytical instruments like Continuous
                                    centrifuge, Zeta Cad, Goniometer, Rheometer,
                                    UV-Vis spectrophotometer, Zetasizer, Probe
                                    sonicator, Porometer, Gel permeation
                                    Chromatograph, total organic carbon
                                    analyzer, rheometer, various membrane
                                    modules, unstirred and stirred cell,
                                    rectangular cross flow cell, hollow fibre
                                    set up for membrane separations.
                                </p>
                                <p>
                                    The facility has created national and
                                    international visibility through his high
                                    quality international publications (225),
                                    patents (15), books (7), book chapters (10)
                                    and technology transfers (2).
                                </p>
                                <p>
                                    <b>Contact:</b> Prof. Shirshendu De
                                    <a href="mailto:sde@che.iitkgp.ac.in">
                                        sde@che.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseEight"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseEight"
                            >
                                Microfluidics and Microscale Transport Processes
                                Laboratory
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseEight"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    The Microfluidics research group is
                                    dedicated to investigating the underlying
                                    physics of micro/nano-scale transport
                                    processes. Microfluidics has been the
                                    gateway to exploring new and fascinating
                                    interfacial phenomena, stemming from the
                                    high surface-to-volume ratio of the involved
                                    systems. These new insights, in turn, have
                                    contributed to the development of even more
                                    sophisticated application based
                                    technologies. These technologies encompass
                                    the varied fields of biotechnology,
                                    biomedical engineering, chemical
                                    engineering, material handling and thermal
                                    management of electronic devices/systems.
                                </p>
                                <p>
                                    The equipment at the laboratory includes
                                    Reflection-Transmission Microscope, CCD
                                    Camera and Strobe light, Peristaltic Pump,
                                    Syringe Pump, Micro-array Spotter, Plasma
                                    Cleaner, Ellipsometer, AC Servo Motor,
                                    Potentiostat/Galvanostat, Tensiometer, Spin
                                    Coater, Pressure Transducer, Digital
                                    Balance, De-ionised Water supply, Table-top
                                    CNC and Cutter/Plotter, Goniometer, Table
                                    top Mask Alligner and Confocal Microscope.
                                </p>
                                <p>
                                    <b>Contact:</b> Prof. Suman Chakraborty{" "}
                                    <a href="mailto:suman@mech.iitkgp.ac.in">
                                        suman@mech.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseNine"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseNine"
                            >
                                National Facility of Stable Isotope Geochemistry
                                (NAFSIG)
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseNine"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    The National Facility of Stable Isotope
                                    Geochemistry (NAFSIG) at the Indian
                                    Institute of Technology, Kharagpur was set
                                    up in April 2004. The specific goal of this
                                    facility was to provide stable isotope data
                                    to researchers especially from Universities
                                    and to undertake Isotope ratio mass
                                    spectrometer (IRMS) based programmes on
                                    certain challenging areas of Earth and
                                    Environmental sciences. Initially funded by
                                    the DST, the facility was later augmented
                                    with the help of a Diamond Jubilee grant of
                                    IIT Kharagpur.
                                </p>
                                <p>
                                    The IIT facility is equipped with three mass
                                    spectrometers, two continuous flow and one
                                    dual inlet along with several peripheral
                                    equipment like Gas bench, Elemental
                                    analyser, TC-EA, GC coupled with IRMS, laser
                                    ablation silicate and carbonate analytical
                                    lines capable of analyzing different types
                                    of geological materials including water,
                                    carbonate, sulphide/sulphate, organic
                                    matter, graphite, clay, and silicates. This
                                    is the first stable isotope national
                                    facility in a university earth science
                                    department in India.
                                </p>
                                <p>
                                    Over the last 12 years, researchers from
                                    various universities and institutions have
                                    carried out experiments in the facility and
                                    published high quality research
                                    publications. A large number of students
                                    (M.Sc, M.Tech. and Ph.D) have also been
                                    trained on stable isotope experiments and
                                    applications in various facets of earth
                                    sciences. Some of the major programmes of
                                    the facility are isotope hydrology of rain
                                    and groundwater, evolution of the Himalayan
                                    foreland and Ganges delta, coal basins of
                                    western India, and archaeological
                                    investigation in Indus Valley Civilization.
                                </p>
                                <p>
                                    <b>Contact:</b> Prof. Anindya Sarkar{" "}
                                    <a href="mailto:anindya@gg.iitkgp.ac.in">
                                        anindya@gg.iitkgp.ac.in
                                    </a>
                                    ; Prof. M K Bera{" "}
                                    <a href="mailto:melinda@gg.iitkgp.ac.in">
                                        melinda@gg.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTen"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTen"
                            >
                                National Facility for Electron Probe
                                Micro-Analysis (NAFEPMA)
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseTen"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    The Department of Geology and Geophysics has
                                    an electron probe micro analyzer (EPMA),
                                    successfully running since December, 2008 as
                                    a DST-IIT Kharagpur National Facility. The
                                    facility developed under the intensification
                                    of research on high priority areas (IRHPA)
                                    program of DST also consists of an ancillary
                                    SEM lab. The primary objective of the
                                    national facility is to provide high quality
                                    compositional information from micron-sized
                                    domains required in materials research with
                                    a focus on applications in mineralogy,
                                    petrology and ore geology.
                                </p>
                                <p>
                                    The SEM is equipped with a Peltier-cooled
                                    EDS detector for obtaining semi-quantitative
                                    chemical analysis in addition to high
                                    magnification imaging using back scattered
                                    electrons (BSE), secondary electrons (SE)
                                    and cathodoluminescence (CL). The EPMA is
                                    capable of measuring concentrations as low
                                    as 1000 ppm of all elements between boron
                                    and uranium in the periodic table. Wide
                                    varieties of materials including minerals
                                    and rocks, alloys, ceramics and polymers
                                    have been analyzed by a spectrum of users
                                    covering the academia and industry as well
                                    as various national laboratories from all
                                    over the country. Within the institute,
                                    apart from users from the department,
                                    students and faculty members from other
                                    departments such as metallurgy, mechanical
                                    and civil engineering have been frequent
                                    users of the facility. In the last 8 years,
                                    12 Ph.D. theses with a strong dependence on
                                    EPMA data have been produced in the
                                    department, apart from several M.Sc. and
                                    M.Tech. dissertations. Over 15 more Ph.D.
                                    scholars are presently working on problems
                                    with major EPMA-centric research components.
                                    In addition to performing high quality
                                    micro-beam chemical analyses, the facility
                                    has pioneered within the country in the
                                    development of specialized protocols for
                                    U-Th-Pbtotal dating of geological events
                                    using monazite, uraninite, xenotime and
                                    produced trained manpower in using EPMA.
                                </p>
                                <p>
                                    <b>Contact:</b> Prof. Biswajit Mishra{" "}
                                    <a href="mailto:bmgg@gg.iitkgp.ac.in">
                                        bmgg@gg.iitkgp.ac.in
                                    </a>
                                    ; Prof. K. L. Pruseth{" "}
                                    <a href="mailto:pruseth@gg.iitkgp.ac.in">
                                        pruseth@gg.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseEleven"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseEleven"
                            >
                                Rural Technology Action Group - Eastern India
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseEleven"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    Coordinated by the office of the Principal
                                    Scientific Adviser to the Government of
                                    India, Rural Technology Action Groups
                                    (RuTAGs) have been set up to act as
                                    application oriented Research and
                                    Development (R&D) nuclei in order to provide
                                    much-needed science and technology (S&T)
                                    support for rural entrepreneurs and users
                                    and to promote potential technologies for
                                    them.
                                </p>
                                <p>
                                    RuTAG-IIT Kharagpur was initiated in the
                                    year 2008 under Prof. PBS Bhadoria as
                                    coordinator. Since then, it has been
                                    actively interacting with NGOs in the
                                    adjoining region including the states of
                                    West Bengal, Odisha, Jharkhand and Bihar to
                                    contribute to the needful.
                                </p>
                                <p>
                                    During the last eight years RuTAG - Eastern
                                    India has connected with more than 50 NGOs
                                    and taken up technological initiatives
                                    aiming to improve the livelihood of clusters
                                    of rural population. It aims to identify the
                                    technological problems and bottlenecks in
                                    rural areas through NGOs, SHG and
                                    entrepreneurs; to develop or fabricate
                                    processes/products/equipment; to solve the
                                    identified problems through peoples
                                    participation; to disseminate established
                                    technologies through training, workshops and
                                    demonstrations and finally, to orient and
                                    expose the undergraduate students to rural
                                    problems in order to develop innovative
                                    solutions.
                                </p>
                                <p>
                                    <b>Contact:</b> Prof. PBS Bhadoria{" "}
                                    <a href="mailto:pbsb@agfe.iitkgp.ac.in">
                                        pbsb@agfe.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTwelve"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTwelve"
                            >
                                Security of Internet of Things Laboratory
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseTwelve"
                            className="accordion-collapse collapse"
                        >
                            <div className="accordion-body">
                                <p>
                                    How would the future world look like?
                                    Attempts to answer this question have opened
                                    up pathways for several outstanding
                                    technologies. The Internet of Things (IoT)
                                    is the depiction of the future Internet:
                                    comprising of billions of smart things
                                    interacting and communicating with other
                                    machines, objects and infrastructures.
                                    However, without addressing the quite
                                    formidable communication and security
                                    mechanisms for all these building blocks,
                                    the IoT would not be as pervasive as
                                    anticipated.
                                </p>
                                <p>
                                    The laboratory on Security of IoT, aims to
                                    design, evaluate, and deploy the essential
                                    communication and security mechanisms for
                                    the IoT paradigm. The Internet Security
                                    Protocol currently rests on well-known and a
                                    widely trusted set of cryptographic
                                    algorithms. But a significant amount of
                                    resources such as processor speed and memory
                                    are expected for functioning of the
                                    application scenario, something which is not
                                    always available in the context of IoTs.
                                </p>
                                <p>
                                    Researchers at the laboratory plan to
                                    undertake a fresh approach by considering
                                    new hardware security primitives and to
                                    discover new verification techniques to
                                    evaluate security against powerful side
                                    channels, and provide much needed trust to
                                    the users against powerful adversaries. The
                                    laboratory is collaborating with DRDO,
                                    CDAC-Bangalore, Wipro, ISEA, Masaryk
                                    University (Czech Republic).
                                </p>
                                <p>
                                    For more on the laboratory and its
                                    operations, click here.
                                </p>
                                <p>
                                    <b>Contact:</b> Prof. Debdeep Mukhopadhyay{" "}
                                    <a href="mailto:debdeep@cse.iitkgp.ac.in">
                                        debdeep@cse.iitkgp.ac.in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h1
                className="main-head"
                style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            >
                <span style={{ fontSize: "40px" }}>|</span>Research Initiatives
            </h1>
            <div className="research-initiatives">
                <p>
                    <b>
                        Noteworthy initiatives which reinforce the quality and
                        breadth of our research enterprise
                    </b>
                </p>
                <h3 style={{ margin: "1rem 0" }}>Megaprojects</h3>
                <p>
                    IIT Kharagpur has launched a set of megaprojects, which are
                    unique in terms of their outreach towards ambitious science
                    and technology missions of national importance. Included in
                    this research portfolio are four projects sponsored by the
                    Ministry of Human Resources Development (MHRD), Government
                    of India:
                </p>
                <p>
                    <a href="#">Future of Cities</a> focuses on technology for
                    the development and maintenance of our cities and future
                    cities.
                </p>
                <p>
                    <a href="#">Signals and Systems for Life Sciences</a>{" "}
                    focuses on technology for leveraging biometric signal
                    processing for analysis, prognostics, diagnostics and
                    affordable healthcare.
                </p>
                <p>
                    <a href="#">The Sustainable Food Security</a> project seeks
                    to leverage technology for food production, processing and
                    distribution logistics.
                </p>
                <p>
                    <a href="#">SandHI</a> (Science and Heritage Interface), a
                    science-culture initiative uses technology for preservation,
                    archival, development and scientific exploration of our
                    heritage.
                </p>
                <p>
                    Each of these initiatives brings together researchers from
                    various departments, centres and schools, showcasing the KGP
                    standards of excellence in cross-disciplinary research and
                    collaboration.
                </p>
                <h3 style={{ margin: "1rem 0" }}>Challenge Grants</h3>
                <p>
                    With a view to encouraging original and pathbreaking
                    research in core engineering as well as cross-disciplinary
                    fields, the Institute has devised an extremely competitive
                    scheme under which seed infrastructure grants are provided
                    to individual faculty members, departments as well as
                    collaborative research groups.
                </p>
                <p>These include:</p>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Open Competitive Grand Challenge for interdisciplinary
                        research groups
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Competitive departmental infrastructure grants for
                        Departments/Centres/Schools to build research
                        infrastructure in an area of emerging thrust
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>Competitive challenge grants for individual research</p>
                </div>
                <h3 style={{ margin: "1rem 0" }}>
                    Institute Scheme for Innovative Research and Development
                    (ISIRD)
                </h3>
                <p>
                    This scheme was launched with a mission to encourage and
                    support new faculty members to initiate cutting-edge
                    research in their respective fields of study. The institute
                    provides funds up to Rs 25 lakh to new faculty members to
                    acquire equipment and infrastructure pertinent to their
                    research with an additional amount of Rs 3 lakh for
                    consumables, contingency etc.
                </p>
                <h3 style={{ margin: "1rem 0" }}>
                    Other Research Initiatives under Institute Support
                </h3>
                <div>
                    <img
                        src="https://www.iitkgp.ac.in/assets/images/about_01.jpg"
                        alt="other research"
                        style={{
                            width: "100%",
                            height: "60vh",
                            objectFit: "cover",
                            objectPosition: "bottom",
                        }}
                    />
                    <div>
                        <h3 style={{ margin: "1rem 0" }}>
                            Centre for Excellence in Robotics
                        </h3>
                        <p>
                            The Centre for Excellence in Robotics was set up
                            with an initial funding of Rs 5 crore from IIT
                            Kharagpur in 2015. It is envisioned as an umbrella
                            body, under the aegis of which all robotics-related
                            activities of the Institute will be conducted and
                            coordinated. The main emphasis of the centre is on
                            the design and development of intelligent and
                            autonomous robots and work on research problems and
                            innovative projects that extend the state of the art
                            in robotics. The vision behind it is to promote and
                            encourage student activities, research projects, and
                            relevant courses related to this field. A large
                            number of faculty members as well as students from
                            various departments are part of this centre. For
                            more information on this initiative, click here
                        </p>
                        <p>
                            <b>Contact:</b> Professor D K Pratihar at{" "}
                            <a href="mailto:dkpra@mech.iitkgp.ac.in">
                                dkpra@mech.iitkgp.ac.in
                            </a>
                        </p>
                    </div>
                </div>
                <h2 style={{ margin: "1rem 0" }}>Centre for Microfluidics</h2>
                <p>
                    The Microfluidics research group is dedicated towards
                    investigating the underlying physics of micro/nano-scale
                    transport processes. Microfluidics has been the gateway to
                    exploring new and fascinating interfacial phenomena,
                    stemming from the high surface-to-volume ratio of the
                    involved systems. These new insights, in turn, have
                    contributed to the development of even more sophisticated
                    application based technologies. These encompass the varied
                    fields of biotechnology, biomedical engineering, chemical
                    engineering, material handling and thermal management of
                    electronic devices/systems.
                </p>
                <p>
                    Presently, the principal research domains being explored by
                    this group include low-cost, high-throughput lab-on-a-CD and
                    paper-based microfluidic platforms for rapid clinical
                    diagnostics, electrically-mediated microdroplet motion and
                    thin film spreading for optimized electronic cooling,
                    droplet-based microfluidic systems for bio-chemical
                    analysis, material handling, targeted drug delivery, and
                    cell-bubble interactions.
                </p>
                <p>
                    The microfluidics research group also addresses challenging
                    problems by implementing state-of-the-art computational
                    fluid dynamics (CFD) and molecular dynamics simulation (MDS)
                    tools. Very recently, the group has ventured into the novel,
                    but very nascent domain of optically induced alteration of
                    microscale interfacial phenomena.
                </p>
                <p>
                    <b>Contact:</b> Professor Suman Chakraborty at{" "}
                    <a href="mailto:suman@mech.iitkgp.ac.in">
                        suman@mech.iitkgp.ac.in
                    </a>
                </p>
                <h2 style={{ margin: "1rem 0" }}>
                    Centre for Artificial Intelligence for Societal Needs
                </h2>
                <p>
                    "Artificial Intelligence for Societal Needs" is an
                    interdisciplinary research initiative under which several
                    projects have been undertaken. These use artificial
                    intelligence (AI), machine learning (ML) and computational
                    techniques for intelligent decision making for solving
                    problems in the Indian context, related to Energy, Climate,
                    Water, Disaster Management and Traffic. The different
                    projects explore AI and ML techniques for
                </p>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Monitoring the dynamics of power systems for generating
                        alarms and mitigating the effects of disturbances
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Short term and medium term climate prediction tasks
                        related to extreme events and monsoon rainfall
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Building an inundation model for the city of Kolkata by
                        using real-time rainfall data and Doppler weather data
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Studying past cyclone tracks to model storm surges and
                        associated coastal inundation
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Parallel real-time implementations of short-term ocean
                        forecast system with advanced initialization and
                        data-assimilative capability for the Bay of Bengal
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Modelling groundwater recharge dynamics for estimating
                        future groundwater trends in the Indian subcontinent
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Building services for different stakeholders in an
                        intelligent transportation system to improve different
                        aspects of logistics efficiency including monitoring,
                        trip-planning and bidding
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Infrastructure planning and management in coastal areas
                    </p>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <i className="bi bi-check-circle"></i>{" "}
                    <p>
                        Routing decisions related to transportation of hazardous
                        materials and waste, location allocation for waste
                        generation and planning for augmentation of transport
                        network.
                    </p>
                </div>
                <p>
                    <b>Contact:</b> Professor Sudeshna Sarkar at{" "}
                    <a href="mailto:sudeshna@cse.iitkgp.ac.in">
                        sudeshna@cse.iitkgp.ac.in
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Researchers;
