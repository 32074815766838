import React from "react";
import { Link } from "react-router-dom";
import "./Phdpage.css";
const Phdpage = () => {
    return (
        <div className="CompPhp">
            <div className="phdpage">
                <img
                    // src="https://thumbs.dreamstime.com/b/female-college-student-books-outdoors-beautiful-young-woman-backpack-carrying-lots-campus-95821586.jpg"
                    src="https://plus.unsplash.com/premium_photo-1663079426406-1b82fed16a79?q=80&w=1830&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="phd-image"
                    className="phd-image"
                />
                <div className="welcomeText">
                    <h1>Find your dream study</h1>
                    <h5> Discover PhD degrees Related to Physics </h5>
                </div>
            </div>

            <div className="phdStreams">
                <h2>Choose Your PhD Stream</h2>
                <ul className="streamList">
                    <li>
                        <Link to="phdpagesub" className="card1">
                            <i className="fas fa-fire-alt"></i>
                            <p>PhD in Fluid Thermodynamic Engineering</p>
                        </Link>
                    </li>
                    <li>
                        <i className="fas fa-lightbulb"></i>
                        <p>PhD in Optics and Photonics</p>
                    </li>
                    <li>
                        <i className="fas fa-atom"></i>
                        <p>Ph.D. in Theoretical Physics</p>
                    </li>
                    <li>
                        <i className="fas fa-magnet"></i>
                        <p>PhD in Applied Physics</p>
                    </li>
                    <li>
                        <i className="fas fa-rocket"></i>
                        <p>Ph.D. of Particle Physics</p>
                    </li>
                    <li>
                        <i className="fas fa-braille"></i>
                        <p>PhD in Condensed Matter Physics</p>
                    </li>

                    <li>
                        <i className="fas fa-lightbulb"></i>
                        <p>PhD in Optics and Photonics</p>
                    </li>
                    <li>
                        <i className="fas fa-fire-alt"></i>
                        <p>PhD in Fluid Thermodynamic Engineering</p>
                    </li>
                </ul>
            </div>

            <div className="requirementsContainer">
                <div className="twoRequire">
                    <div className="requirementSection">
                        <h3>Academic Requirements</h3>
                        <div className="requirementDetails">
                            <p>
                                <strong>GPA:</strong> 2.75
                            </p>
                            <p>
                                <strong>English Requirements:</strong>
                            </p>
                            <ul>
                                <li>IELTS: 6.5</li>
                                <li>TOEFL iBT: 79</li>
                            </ul>
                            <p>
                                <strong>Other Requirements:</strong>
                            </p>
                            <ul>
                                <li>
                                    Bachelor's degree or higher from an
                                    accredited college or university
                                </li>
                                <li>
                                    Minimum 2.750 GPA on a 4.000 point scale
                                </li>
                                <li>Official transcripts</li>
                                <li>GRE (general) scores (optional)</li>
                                <li>Résumé or vita</li>
                                <li>Goal statement</li>
                                <li>Three letters of recommendation</li>
                                <li>English language proficiency</li>
                            </ul>
                            <a href="#">Make sure you meet all requirements</a>
                        </div>
                    </div>

                    <div className="requirementSection">
                        <h3>Funds from the Institute</h3>
                        <div className="fundDetails">
                            <ul>
                                <li>Stipends</li>
                                <li>Scholarships</li>
                                <li>Tuition Waivers</li>
                                <li>Research Grants</li>
                                <li>Research Assistantships (RA)</li>
                                <li>Teaching Assistantships (TA)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="key-information">
                    <h2>Key Information</h2>
                    <div className="info">
                        <div className="start-dates">
                            At IIT Kharagpur, we provide an environment where
                            you can thrive in your academic and research
                            pursuits. Our comprehensive support system and
                            resources are designed to help you navigate your
                            career path effectively. With a focus on innovation
                            and collaboration, you will have the opportunity to
                            engage in impactful research, contribute to
                            groundbreaking projects, and work alongside leading
                            experts in your field. Start your journey with us
                            and unlock your potential in a dynamic and
                            supportive setting!<br/><br/>
                            <h3>Start Dates & Application Deadlines</h3>
                            <p style={{ color: "#281981" }}>
                                <strong style={{ color: "black" }}>
                                    Starting January 2025
                                </strong>
                            </p>
                            <p style={{ color: "red" }}>
                                Apply before Sep 2024
                            </p>
                            <p style={{ color: "#281981" }}>
                                <strong style={{ color: "black" }}>
                                    Starting August 2025
                                </strong>
                            </p>
                            <p style={{ color: "red" }}>
                                Apply before Feb 2025
                            </p>
                        </div>
                        <div className="language" style={{ color: "red" }}>
                            <h3 style={{ color: "black" }}>Language</h3>
                            <p style={{ color: "black" }}>English</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Phdpage;
