import React from "react";
import "./Events.css";

const Events = () => {
    return (
        <div>
            <div class="background-E">
                <div class="content-E">
                    <h1 class="headline-E">EVENTS</h1>
                    <p class="paragraph-E">
                        This is a sample paragraph that provides some
                        information about our events. Enjoy exploring!
                    </p>
                    <div class="buttons-E">
                        <a href="#" class="btn-E">
                            Learn More
                        </a>
                        <a href="#" class="btn-E">
                            Get Started
                        </a>
                    </div>
                </div>
            </div>
            <div class="features-E">
                <div class="feature-E">
                    <i class="fa-solid fa-camera-retro"></i>
                    <h3>Reliable Design Principles</h3>
                    <p>
                        Our design approach ensures that IIT Kharagpur’s digital
                        platforms perform seamlessly and maintain a professional
                        appearance across all devices, including desktops,
                        tablets, and mobile phones.
                    </p>
                </div>
                <div class="feature-E">
                    <i class="fa-solid fa-lightbulb"></i>
                    <h3>Resource Management</h3>
                    <p>
                        Affordable and efficient equipment rental services at
                        IIT Kharagpur include streamlined payment options for
                        easy transactions, accommodating debit/credit cards, net
                        banking, and UPI.
                    </p>
                </div>
                <div class="feature-E">
                    <i class="fa-solid fa-hashtag"></i>
                    <h3>Social Media Integration</h3>
                    <p>
                        Enhance your academic and research outreach by
                        integrating social media features into IIT Kharagpur’s
                        digital platforms. This approach increases engagement
                        and connects with a broader audience beyond your social
                        profiles.
                    </p>
                </div>
                <div class="feature-E">
                    <i class="fa-solid fa-clock"></i>
                    <h3>24/7 Support</h3>
                    <p>
                        At IIT Kharagpur, we recognize the importance of
                        continuous support for academic and research activities.
                        Our dedicated team provides round-the-clock assistance
                        to ensure the smooth operation and efficiency of all
                        university functions.
                    </p>
                </div>
            </div>

            <div class="conference-E">
                <div class="icon-E">
                    <i class="fas fa-globe"></i>
                </div>
                <h2>ABOUT EVENT</h2>
                <p>
                    Our new program equips students with essential skills and
                    knowledge to plan, organize, and manage special events
                    across public, corporate, and non-profit sectors. Students
                    will learn to develop community awareness, engage the
                    public, enhance quality of life, generate revenue, and
                    market products effectively.
                </p>
                <div class="stats-E">
                    <div class="stat-E">
                        <h3>575</h3>
                        <p>PARTICIPANTS</p>
                    </div>
                    <div class="stat-E">
                        <h3>76</h3>
                        <p>PROGRAM</p>
                    </div>
                    <div class="stat-E">
                        <h3>28</h3>
                        <p>SESSIONS</p>
                    </div>
                    <div class="stat-E">
                        <h3>49</h3>
                        <p>SPEAKER</p>
                    </div>
                </div>
                <p class="register-E">
                    Hurry Up! Only <strong>198</strong> seats
                </p>
                <a href="#" class="register-btn-E">
                    Know more
                </a>
            </div>

            <div class="events-container-E">
                <div class="event-card-E">
                    <img
                        src="https://img.freepik.com/premium-photo/cheerful-guys-party-birthday-party-confetti_85574-458.jpg"
                        alt="Silver Jubilee Reunion Class of 1995"
                    />
                    <div class="event-content-E">
                        <h3>Silver Jubilee Reunion Class of 1995</h3>
                        <p>Feb 26, 2024</p>
                        <p>IIT-KGP, Main Gate Rd, IIT-KGP Campus</p>
                    </div>
                </div>
                <div class="event-card-E">
                    <img
                        src="https://img.freepik.com/premium-photo/cheerful-guys-party-birthday-party-confetti_85574-458.jpg"
                        alt="Alumni Day Celebrations"
                    />
                    <div class="event-content-E">
                        <h3>Annual day Celebrations</h3>
                        <p>December 25, 2021</p>
                        <p>VMCC – Nag Auditorium / IIT-KGP Campus...</p>
                    </div>
                </div>
                <div class="event-card-E">
                    <img
                        src="https://img.freepik.com/premium-photo/cheerful-guys-party-birthday-party-confetti_85574-458.jpg"
                        alt="Silver Jubilee Reunion Class of 1997"
                    />
                    <div class="event-content-E">
                        <h3>Silver Jubilee Reunion Class of 1997</h3>
                        <p>December 23, 2022</p>
                        <p>IIT Kharagpur, Main Gate Rd, IIT Kharagpur Campus</p>
                    </div>
                </div>
            </div>
            <div class="container-E">
                <div class="text-content-E">
                    <h1>Upcoming Events</h1>
                    <p>
                        Our annual function, held every March, brings together a
                        diverse group of participants, including faculty
                        members, researchers, students, industry professionals,
                        and government officials. This event takes place at our
                        main campus in Kharagpur and provides valuable
                        opportunities for networking, knowledge sharing, and
                        collaboration.
                    </p>
                    <a href="#">Discover now</a>
                </div>
                <div class="image-content-E">
                    <img
                        src="https://th.bing.com/th/id/OIP.o8d0STltT9yqVBNZrR96DgHaE8?w=265&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7  "
                        alt="Conference  1"
                    />
                    <img
                        src="https://th.bing.com/th/id/OIP.o8d0STltT9yqVBNZrR96DgHaE8?w=265&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7  "
                        alt="Conference 2"
                    />
                    <img
                        src="https://th.bing.com/th/id/OIP.o8d0STltT9yqVBNZrR96DgHaE8?w=265&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7  "
                        alt="Conference  3"
                    />
                    <img
                        src="https://th.bing.com/th/id/OIP.o8d0STltT9yqVBNZrR96DgHaE8?w=265&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7  "
                        alt="Conference 3"
                    />
                </div>
            </div>
        </div>
    );
};

export default Events;
